import { useGetCustomer } from "@api/customers/useGetCustomer";
import Modal from "@components/Modal/Modal";
import { SaleHistory } from "@pxp-caisse/shared/types/sale";

type SaleCustomerInfoModalProps = {
  sale: SaleHistory;
  isOpen: boolean;
  onClose: () => void;
};

function SaleCustomerInfoModal({
  sale,
  isOpen,
  onClose,
}: SaleCustomerInfoModalProps) {
  const { data } = useGetCustomer({ id: sale.customerId });
  const fullName = (
    (data?.customer.firstName ?? "") +
    " " +
    (data?.customer.lastName ?? "")
  ).trim();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Informations client pour la vente n°" + sale.ticketNumber}
    >
      <div style={{ display: "grid", gap: 10, padding: 20 }}>
        <p>
          <b>Nom :</b> {!!fullName ? fullName : "Nom inconnu"}
        </p>
        <p>
          <b>Genre :</b> {data?.customer.gender === 1 ? "F" : "H"}
        </p>
        <p>
          <b>Email :</b> {data?.customer.email}
        </p>
        <p>
          <b>Téléphone :</b> {data?.customer.mobile}
        </p>
      </div>
    </Modal>
  );
}

export default SaleCustomerInfoModal;
