import styles from "./page.module.css";

import { useEffect, useState } from "react";
import { SelectValue } from "@components/Select/Select";
import Sidebar from "@components/Sidebar/Sidebar";
import TopBar from "@components/TopBar/TopBar";
import { useAuthContext } from "@providers/AuthContext";
import { logoutUser } from "@api/auth/logout";
import { useGetProducts } from "@api/products/get";
import { useGlobalContext } from "@providers/GlobalProvider";
import { useCart } from "@providers/CartProvider";
import { useGetCarts } from "@api/carts/get";
import { Outlet } from "react-router-dom";

type LayoutProps = {
  children?: React.ReactNode;
  withOutlet?: boolean;
};

export default function Layout({ children, withOutlet = false }: LayoutProps) {
  const [selectedCart, setSelectedCart] = useState<SelectValue>(1);

  const { logout } = useAuthContext();

  const { data: products } = useGetProducts();
  const { updateProducts } = useGlobalContext();

  const { data: carts } = useGetCarts();
  const { setCurrentCartIndex, loadCarts } = useCart();

  useEffect(() => {
    updateProducts(products);
    loadCarts(carts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, carts]);

  const handleCartChange = (value: SelectValue) => {
    setSelectedCart(value);

    const index = (value as number) - 1;
    setCurrentCartIndex(index);
  };

  const handleLogout = async () => {
    await logoutUser().catch((e) => console.error(e));
    logout();
  };

  return (
    <main className={styles.container}>
      <div className={styles.row}>
        <Sidebar onLogout={handleLogout} />
        <div className={styles.column}>
          <TopBar currentCart={selectedCart} onCartChange={handleCartChange} />
          <div className={styles.children}>
            {withOutlet ? <Outlet /> : children}
          </div>
        </div>
      </div>
    </main>
  );
}
