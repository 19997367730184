import clsx from "clsx";
import { SelectedOption, SelectValue } from "../Select/Select";
import styles from "./TagSelect.module.css";

export default function TagSelect({
  options,
  value,
  onChange,
}: {
  options: SelectedOption[];
  value: SelectValue;
  onChange: (value: SelectValue) => void;
}) {
  return (
    <div className={styles.container}>
      {options.map((option) => {
        return (
          <div className={styles.tagContainer} key={option.value}>
            <button
              className={clsx(styles.tag, {
                [styles.selected]: option.value === value,
              })}
              onClick={() => onChange(option.value)}
              disabled={option.value === value}
            >
              <p>{option.name}</p>
            </button>
            {value === option.value && (
              <img
                src="/icons/close-x.svg"
                alt="X"
                className={styles.crossIcon}
                onClick={() => onChange("")}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
