import { CSSProperties } from "react";
import styles from "./Table.module.css";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import clsx from "clsx";

type TableProps = {
  style?: CSSProperties;
  className?: string;
  children: [
    React.ReactElement<typeof TableHeader>,
    React.ReactElement<typeof TableBody>
  ];
  scrollable?: boolean;
};

const Table = ({
  style,
  children,
  className,
  scrollable = false,
}: TableProps) => {
  return (
    <div
      className={clsx(styles.container, className, {
        [styles.scrollable]: scrollable,
      })}
      style={style}
    >
      <table>{children}</table>
    </div>
  );
};

export default Table;
