import CurrencyInput from "@components/CurrencyInput/CurrencyInput";
import styles from "./styles/CashInputCard.module.css";
import { toTwoDecimals } from "@pxp-caisse/shared/utils/conversion";
import clsx from "clsx";

export const BillsInputDefaultValues: CashInputValuesType[] = [
  {
    value: 5,
    quantity: 0,
    label: "5",
    id: "5",
  },
  {
    value: 10,
    quantity: 0,
    label: "10",
    id: "10",
  },
  {
    value: 20,
    quantity: 0,
    label: "20",
    id: "20",
  },
  {
    value: 50,
    quantity: 0,
    label: "50",
    id: "50",
  },
  {
    value: 100,
    quantity: 0,
    label: "100",
    id: "100",
  },
  {
    value: 200,
    quantity: 0,
    label: "200",
    id: "200",
  },
  {
    value: 500,
    quantity: 0,
    label: "500",
    id: "500",
  },
];

export const CoinsInputDefaultValues: CashInputValuesType[] = [
  {
    value: 0.01,
    quantity: 0,
    label: "1 cent",
    id: "0.01",
  },
  {
    value: 0.02,
    quantity: 0,
    label: "2 cent",
    id: "0.02",
  },
  {
    value: 0.05,
    quantity: 0,
    label: "5 cent",
    id: "0.05",
  },
  {
    value: 0.1,
    quantity: 0,
    label: "10 cent",
    id: "0.1",
  },
  {
    value: 0.2,
    quantity: 0,
    label: "20 cent",
    id: "0.2",
  },
  {
    value: 0.5,
    quantity: 0,
    label: "50 cent",
    id: "0.5",
  },
  {
    value: 1,
    quantity: 0,
    label: "1",
    id: "1",
  },
  {
    value: 2,
    quantity: 0,
    label: "2",
    id: "2",
  },
];

export type CashInputValuesType = {
  value: number;
  quantity: number;
  label: string;
  id: string;
};

type CashInputCardProps = {
  label?: string;
  currency?: string;
  values: CashInputValuesType[];
  setValues: (values: CashInputValuesType[]) => void;
  editable?: boolean;
};

function CashInputCard({
  label,
  currency = "€",
  values,
  setValues,
  editable = true,
}: CashInputCardProps) {
  const splitIntoChunks = (
    arr: CashInputValuesType[],
    chunkSize = 4
  ): CashInputValuesType[][] => {
    const result: CashInputValuesType[][] = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const handleChange = (quantity: number, id: string) => {
    const newValues = values.map((v) => {
      if (v.id === id) {
        return {
          ...v,
          quantity,
        };
      }
      return v;
    });
    setValues(newValues);
  };

  return (
    <div className={styles.container}>
      {label && <p>{label}</p>}
      <div className={styles.card}>
        {splitIntoChunks(values).map((chunk, index) => (
          <div className={styles.column} key={index}>
            {chunk.map((value, index) => (
              <div
                className={clsx(styles.line, {
                  [styles.separator]: index !== chunk.length - 1,
                })}
                key={value.id}
              >
                <div style={{ width: 80 }}>
                  <p>{value.label}</p>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    gap: 20,
                    alignItems: "center",
                  }}
                >
                  <p>x</p>
                  <CurrencyInput
                    disabled={!editable}
                    step="1"
                    value={value.quantity}
                    onChange={(val) => handleChange(val, value.id)}
                    currency=""
                    style={{ width: 80, minWidth: "unset", height: 40 }}
                  />
                  <p>=</p>
                </div>
                <p style={{ width: 80, textAlign: "end" }}>
                  {toTwoDecimals(value.quantity * value.value)} {currency}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CashInputCard;
