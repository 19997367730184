import clsx from "clsx";
import { useState, useEffect, CSSProperties, useRef } from "react";
import styles from "./Modal.module.css";
import { createPortal } from "react-dom";

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  animate?: boolean;
  type?: "default" | "sidePanel";
  title?: string;
  width?: CSSProperties["width"];
  maxHeight?: CSSProperties["maxHeight"];
};

const stylesByType: Record<
  ModalProps["type"],
  {
    content: string;
    animationIn: string;
    animationOut: string;
  }
> = {
  default: {
    content: styles.modalContent,
    animationIn: styles.fadeIn,
    animationOut: styles.fadeOut,
  },
  sidePanel: {
    content: styles.sidePanel,
    animationIn: styles.slideIn,
    animationOut: styles.slideOut,
  },
};

const Modal = ({
  isOpen,
  onClose,
  children,
  animate = true,
  type = "default",
  title,
  width = "auto",
}: ModalProps) => {
  const [show, setShow] = useState(isOpen);
  const contentStyles = stylesByType[type];

  const [isScrollable, setIsScrollable] = useState(false);
  const [hasAnimationPlayed, setHasAnimationPlayed] = useState(false);

  const overlayRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef(null);
  const animationFinished = useRef(hasAnimationPlayed);

  useEffect(() => {
    const target = overlayRef.current;
    if (type !== "sidePanel" && target) {
      const checkOverlayScroll = () => {
        if (target) {
          const isDivScrollable = target.scrollHeight > target.clientHeight;
          setIsScrollable(isDivScrollable);
        }
      };

      checkOverlayScroll();

      const observer = new MutationObserver(checkOverlayScroll);

      const resizeObserver = new ResizeObserver(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          checkOverlayScroll();
        }, 300);
      });

      target.addEventListener("animationend", () => {
        if (animationFinished.current) return;
        checkOverlayScroll();
        setHasAnimationPlayed(true);
      });

      observer.observe(target, {
        childList: true,
        subtree: true,
      });

      resizeObserver.observe(target);

      return () => {
        clearTimeout(timeoutRef.current);
        observer.disconnect();
        resizeObserver.disconnect();
        target.removeEventListener("animationend", checkOverlayScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [type, overlayRef.current, isOpen, show]);

  useEffect(() => {
    if (isOpen) {
      setShow(true);
    } else {
      setTimeout(() => setShow(false), animate ? 300 : 0);
    }
  }, [isOpen, animate]);

  useEffect(() => {
    animationFinished.current = hasAnimationPlayed;
  }, [hasAnimationPlayed]);

  if (!show) return null;

  return createPortal(
    <div
      ref={overlayRef}
      className={clsx(styles.modalOverlay, {
        [styles.fadeIn]: animate,
        [styles.fadeOut]: !isOpen && animate,
      })}
      style={{
        justifyContent: type === "sidePanel" ? "flex-end" : "center",
      }}
      onClick={onClose}
    >
      <div
        className={
          type !== "sidePanel"
            ? clsx(styles.wrapper, { [styles.scrollable]: isScrollable })
            : styles.sidePanelWrapper
        }
        style={{ width }}
      >
        <div
          className={clsx(contentStyles.content, {
            [contentStyles.animationIn]: animate,
            [contentStyles.animationOut]: !isOpen && animate,
          })}
          style={{ width }}
          onClick={(e) => e.stopPropagation()}
        >
          <button onClick={onClose} className={styles.closeButton}>
            <img src="/icons/x.svg" alt="Close" />
          </button>
          {title && (
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>{title}</h2>
            </div>
          )}

          {children}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
