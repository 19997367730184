import clsx from "clsx";
import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from "react";
import styles from "./TextField.module.css";

export type TextFieldProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  leftIconSrc?: string;
  rightComponent?: ReactNode;
  style?: CSSProperties;
  error?: string;
  type?: "text" | "number" | "email" | "password";
  multiline?: boolean;
  label?: string;
  showError?: boolean;
  onBlur?: () => void;
};

const TextField = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      leftIconSrc,
      style,
      error,
      type = "text",
      multiline = false,
      label,
      rightComponent,
      showError = true,
      onBlur,
    }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    return (
      <div className={styles.container}>
        {label && <label className={styles.label}>{label}</label>}
        <div
          className={clsx(styles.wrapper, {
            [styles.error]: error,
          })}
          style={{ ...style }}
        >
          {leftIconSrc && (
            <img
              src={leftIconSrc}
              alt={"Left icon"}
              className={styles.leftIcon}
            />
          )}

          {multiline ? (
            <textarea
              rows={3}
              className={styles.inputContainer}
              style={{ resize: "none", overflowY: "hidden" }}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={placeholder}
              ref={ref as ForwardedRef<HTMLTextAreaElement>}
              onBlur={onBlur}
            />
          ) : (
            <input
              className={styles.inputContainer}
              type={type}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder={placeholder}
              ref={ref as ForwardedRef<HTMLInputElement>}
              onBlur={onBlur}
            />
          )}

          {rightComponent}
        </div>
        {showError && error && <p className={styles.errorMessage}>{error}</p>}
      </div>
    );
  }
);

export default TextField;
