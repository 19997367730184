import { useState } from "react";
import CashInputCard, {
  BillsInputDefaultValues,
  CashInputValuesType,
  CoinsInputDefaultValues,
} from "./components/CashInputCard";
import styles from "./page.module.css";
import clsx from "clsx";
import Button from "@components/Button/Button";
import CurrencyInput from "@components/CurrencyInput/CurrencyInput";
import { useAuthContext } from "@providers/AuthContext";
import { toTwoDecimals } from "@pxp-caisse/shared/utils/conversion";
import GiftCertificatesModal, {
  Certificate,
} from "./components/GiftCertificatesModal";
import { useCloseDesk } from "@api/fund/useCloseDesk";
import TextField from "@components/TextField/TextField";
import { FloatErrorsType } from "@pxp-caisse/shared/types/accounting";
import CloseErrorModal from "./components/CloseErrorModal";
import { toast } from "react-toastify";
import ForceCloseModal from "./components/ForceCloseModal";
import { useCart } from "@providers/CartProvider";
import EmptyState from "@components/Empty/EmptyState";

function CloseDeskPage() {
  const {
    user: { currency },
    logout,
  } = useAuthContext();

  const { carts } = useCart();

  const [totalCashValues, setTotalCashValues] = useState<{
    bills: CashInputValuesType[];
    coins: CashInputValuesType[];
  }>({
    bills: [...BillsInputDefaultValues],
    coins: [...CoinsInputDefaultValues],
  });
  const [bankCashValues, setBankCashValues] = useState<{
    bills: CashInputValuesType[];
    coins: CashInputValuesType[];
  }>({
    bills: [...BillsInputDefaultValues],
    coins: [...CoinsInputDefaultValues],
  });
  const [cashLeftValues, setCashLeftValues] = useState<{
    bills: CashInputValuesType[];
    coins: CashInputValuesType[];
  }>({
    bills: [...BillsInputDefaultValues],
    coins: [...CoinsInputDefaultValues],
  });

  const [totalValues, setTotalValues] = useState<{
    cashLeft: number;
    bankCash: number;
    creditCard: number;
    giftCertificates: number;
  }>({
    cashLeft: 0,
    bankCash: 0,
    creditCard: 0,
    giftCertificates: 0,
  });

  const [cashDisbursement, setCashDisbursement] = useState({
    amount: 0,
    justification: "",
  });

  const [isGiftCertificatesModalOpen, setIsGiftCertificatesModalOpen] =
    useState(false);

  const [addedGiftCertificates, setAddedGiftCertificates] = useState<
    Certificate[]
  >([]);

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorContent, setErrorContent] = useState<
    FloatErrorsType | undefined
  >();

  const [forceCloseModalOpen, setForceCloseModalOpen] = useState(false);
  const [errorJustification, setErrorJustification] = useState("");

  const onAddGiftCertificate = (
    values: Pick<Certificate, "amount" | "type" | "quantity">
  ) => {
    if (values.amount === 0 || values.quantity === 0) return;

    setAddedGiftCertificates((prev) => [
      ...prev,
      { id: Math.floor(Math.random() * 10000), ...values },
    ]);

    setTotalValues({
      ...totalValues,
      giftCertificates:
        totalValues.giftCertificates + values.amount * values.quantity,
    });
  };

  const onRemoveGiftCertificate = (id: number) => {
    const removedCertificate = addedGiftCertificates.find(
      (certificate) => certificate.id === id
    );

    if (!removedCertificate) return;

    setAddedGiftCertificates((prev) =>
      prev.filter((certificate) => certificate.id !== id)
    );

    setTotalValues({
      ...totalValues,
      giftCertificates:
        totalValues.giftCertificates -
        removedCertificate.amount * removedCertificate.quantity,
    });
  };

  const onTotalCashChange = (
    value: CashInputValuesType[],
    type: "bills" | "coins"
  ) => {
    if (type === "bills") {
      setTotalCashValues({
        ...totalCashValues,
        bills: value,
      });

      setCashLeftValues({
        ...cashLeftValues,
        bills: value.map((v) => ({
          ...v,
          quantity:
            v.quantity -
            bankCashValues.bills.find((b) => b.id === v.id).quantity,
        })),
      });

      const newTotalBillsLeft = value.reduce(
        (acc, value) =>
          acc +
          (value.quantity -
            bankCashValues.bills.find((b) => b.id === value.id).quantity) *
            value.value,
        0
      );

      setTotalValues({
        ...totalValues,
        cashLeft:
          newTotalBillsLeft +
          cashLeftValues.coins.reduce(
            (acc, value) => acc + value.quantity * value.value,
            0
          ),
      });
      return;
    }

    if (type === "coins") {
      setTotalCashValues({
        ...totalCashValues,
        coins: value,
      });

      setCashLeftValues({
        ...cashLeftValues,
        coins: value.map((v) => ({
          ...v,
          quantity:
            v.quantity -
            bankCashValues.coins.find((b) => b.id === v.id).quantity,
        })),
      });

      const newTotalCoinsLeft = value.reduce(
        (acc, value) =>
          acc +
          (value.quantity -
            bankCashValues.coins.find((b) => b.id === value.id).quantity) *
            value.value,
        0
      );

      setTotalValues({
        ...totalValues,
        cashLeft:
          newTotalCoinsLeft +
          cashLeftValues.bills.reduce(
            (acc, value) => acc + value.quantity * value.value,
            0
          ),
      });
      return;
    }
  };

  const onBankCashChange = (
    value: CashInputValuesType[],
    type: "bills" | "coins"
  ) => {
    const totalCash =
      totalCashValues.coins.reduce(
        (acc, value) => acc + value.quantity * value.value,
        0
      ) +
      totalCashValues.bills.reduce(
        (acc, value) => acc + value.quantity * value.value,
        0
      );

    if (type === "bills") {
      setBankCashValues({
        ...bankCashValues,
        bills: value,
      });

      setCashLeftValues({
        ...cashLeftValues,
        bills: value.map((v) => ({
          ...v,
          quantity:
            totalCashValues.bills.find((b) => b.id === v.id).quantity -
            v.quantity,
        })),
      });

      const totalBankCash =
        value.reduce((acc, value) => acc + value.quantity * value.value, 0) +
        bankCashValues.coins.reduce(
          (acc, value) => acc + value.quantity * value.value,
          0
        );

      setTotalValues({
        ...totalValues,
        bankCash: totalBankCash,
        cashLeft: totalCash - totalBankCash,
      });
      return;
    }

    if (type === "coins") {
      setBankCashValues({
        ...bankCashValues,
        coins: value,
      });

      setCashLeftValues({
        ...cashLeftValues,
        coins: value.map((v) => ({
          ...v,
          quantity:
            totalCashValues.coins.find((b) => b.id === v.id).quantity -
            v.quantity,
        })),
      });

      const totalBankCash =
        value.reduce((acc, value) => acc + value.quantity * value.value, 0) +
        bankCashValues.bills.reduce(
          (acc, value) => acc + value.quantity * value.value,
          0
        );

      setTotalValues({
        ...totalValues,
        bankCash: totalBankCash,
        cashLeft: totalCash - totalBankCash,
      });
      return;
    }
  };

  const closeDeskMutation = useCloseDesk();

  const onCloseDesk = (force = false) => {
    const bankDetail = [
      bankCashValues.coins.map((coin) => ({
        amount: coin.value,
        quantity: coin.quantity,
      })),
      bankCashValues.bills.map((bill) => ({
        amount: bill.value,
        quantity: bill.quantity,
      })),
    ].flat();

    const cashFloatDetail = [
      cashLeftValues.coins.map((coin) => ({
        amount: coin.value,
        quantity: coin.quantity,
      })),
      cashLeftValues.bills.map((bill) => ({
        amount: bill.value,
        quantity: bill.quantity,
      })),
    ].flat();

    const giftCertificatesDetail = addedGiftCertificates.map((certificate) => ({
      amount: certificate.amount,
      type: certificate.type,
      quantity: certificate.quantity,
    }));

    closeDeskMutation.mutate(
      {
        cashFloat: totalValues.cashLeft,
        cashFloatDetail,
        bankAmount: totalValues.bankCash,
        bankDetail,
        cashDisbursement: cashDisbursement.amount,
        cashDisbursementJustification: cashDisbursement.justification,
        ccTurnover: totalValues.creditCard,
        giftCertificatesTurnover: totalValues.giftCertificates,
        giftCertificatesDetail,
        errorJustification,
        now: new Date(),
        force,
      },
      {
        onSuccess: (data) => {
          if (!data.error) {
            toast.success("Clôture de caisse effectuée.");
            logout();
            return;
          }

          setErrorContent(data.floatErrors);
          setErrorModalOpen(true);
        },
      }
    );
  };

  if (carts.find((cart) => cart.cartItems.length > 0)) {
    return (
      <EmptyState
        label="Veuillez vider les paniers avant de clôturer la caisse"
        icon="/icons/empty-basket.svg"
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>
            <b>Clôturer la caisse</b>
          </h1>
        </div>
        <div className={clsx(styles.section, styles.separator)}>
          <h3>Cash total en caisse</h3>
          <CashInputCard
            label={"Pièces"}
            values={totalCashValues.coins}
            setValues={(values) => onTotalCashChange(values, "coins")}
          />
          <CashInputCard
            label={"Billets"}
            values={totalCashValues.bills}
            setValues={(values) => onTotalCashChange(values, "bills")}
          />
        </div>
        <div className={clsx(styles.section, styles.separator)}>
          <h3>Cash remis en banque</h3>
          <CashInputCard
            label={"Pièces"}
            values={bankCashValues.coins}
            setValues={(values) => onBankCashChange(values, "coins")}
          />
          <CashInputCard
            label={"Billets"}
            values={bankCashValues.bills}
            setValues={(values) => onBankCashChange(values, "bills")}
          />
        </div>
        <div className={clsx(styles.section, styles.separator)}>
          <h3>Cash laissé en caisse</h3>
          <CashInputCard
            label={"Pièces"}
            editable={false}
            values={cashLeftValues.coins}
            setValues={() => {}}
          />
          <CashInputCard
            label={"Billets"}
            editable={false}
            values={cashLeftValues.bills}
            setValues={() => {}}
          />
        </div>
        <div className={styles.totals}>
          <div className={styles.totalsCard}>
            <p>Cash laissé en caisse</p>
            <h2>
              {toTwoDecimals(totalValues.cashLeft)} {currency}
            </h2>
          </div>
          <div className={styles.totalsCard}>
            <p>Cash remis en banque</p>
            <h2>
              {toTwoDecimals(totalValues.bankCash)} {currency}
            </h2>
          </div>
          <div className={styles.totalsCard}>
            <p>Carte bancaire</p>
            <CurrencyInput
              value={totalValues.creditCard}
              currency={currency}
              onChange={(val) =>
                setTotalValues({ ...totalValues, creditCard: val })
              }
              style={{ height: 40, width: 150, minWidth: "unset" }}
            />
          </div>
          <div className={styles.totalsCard}>
            <p>Chèques cadeau</p>
            <h2>
              {toTwoDecimals(totalValues.giftCertificates)} {currency}
            </h2>
            <button
              className={styles.editButton}
              onClick={() => setIsGiftCertificatesModalOpen(true)}
            >
              Modifier
            </button>
          </div>
          <div className={styles.totalsCard}>
            <p>Total</p>
            <h2>
              {toTwoDecimals(
                totalValues.cashLeft +
                  totalValues.bankCash +
                  totalValues.creditCard +
                  totalValues.giftCertificates
              )}{" "}
              {currency}
            </h2>
          </div>
        </div>
        <div className={styles.disbursementSection}>
          <h3>Sortie de caisse</h3>
          <div className={styles.cashDisbursementCard}>
            <div className={styles.disbursementAmount}>
              <p>Montant</p>
              <CurrencyInput
                value={cashDisbursement.amount}
                onChange={(value) =>
                  setCashDisbursement({ ...cashDisbursement, amount: value })
                }
                currency={currency}
                style={{ width: 100, minWidth: "unset", height: 40 }}
              />
            </div>
            <div className={styles.disbursementJustification}>
              <p>Motif</p>
              <TextField
                multiline
                value={cashDisbursement.justification}
                onChange={(value) =>
                  setCashDisbursement({
                    ...cashDisbursement,
                    justification: value,
                  })
                }
                placeholder="Justification"
              />
            </div>
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
        <p>Imprimer</p>
        <Button
          style={{ width: "fit-content" }}
          variant="dark"
          onClick={() => onCloseDesk()}
        >
          Clôturer la caisse
        </Button>
      </footer>
      <GiftCertificatesModal
        isOpen={isGiftCertificatesModalOpen}
        onClose={() => setIsGiftCertificatesModalOpen(false)}
        onAdd={onAddGiftCertificate}
        onRemove={onRemoveGiftCertificate}
        certificates={addedGiftCertificates}
      />
      <CloseErrorModal
        isOpen={errorModalOpen}
        onClose={() => setErrorModalOpen(false)}
        errors={errorContent}
        onForce={() => {
          setForceCloseModalOpen(true);
          setErrorModalOpen(false);
        }}
      />
      <ForceCloseModal
        isOpen={forceCloseModalOpen}
        onClose={() => setForceCloseModalOpen(false)}
        onForce={() => {
          onCloseDesk(true);
        }}
        justificationValue={errorJustification}
        onJustificationChange={setErrorJustification}
      />
    </div>
  );
}

export default CloseDeskPage;
