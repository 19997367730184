import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@components/Table";
import { ColumnType } from "@components/Table/TableHeader";
import { parseFloat2 } from "@pxp-caisse/shared/utils/conversion";
import { SaleHistory } from "@pxp-caisse/shared/types/sale";
import { saleTypesObj } from "@pxp-caisse/shared/constants/sale.const";
import moment from "moment";
import EmptyState from "@components/Empty/EmptyState";
import React, { useState } from "react";
import SaleModal from "./SaleModal";
import Modal from "@components/Modal/Modal";
import styles from "../page.module.css";
import { useAuthContext } from "@providers/AuthContext";

const tableHeader: ColumnType[] = [
  {
    type: "text",
    key: "ticketNumber",
    label: "Ticket",
  },
  {
    type: "text",
    key: "date",
    label: "Date",
  },
  {
    type: "text",
    key: "hour",
    label: "Heure",
  },
  {
    type: "text",
    key: "type",
    label: "Type",
  },
  {
    type: "text",
    key: "collectedBy",
    label: "Encaissé par",
  },
  {
    type: "text",
    key: "seller",
    label: "Vendeur",
  },
  {
    type: "text",
    key: "desk",
    label: "N° caisse",
  },
  {
    type: "text",
    key: "items",
    label: "Nb articles",
  },
  {
    type: "text",
    key: "itemsAvgDiscount",
    label: "Remise",
  },
  {
    type: "text",
    key: "paymentMethod",
    label: "Moyen de paiement",
  },
  {
    type: "text",
    key: "totalPaid",
    label: "Total réglé",
  },
];

type SalesHistoryTableProps = {
  data: SaleHistory[];
  showStoreName?: boolean;
};

function SalesHistoryTable({
  data,
  showStoreName = false,
}: SalesHistoryTableProps) {
  const [openModal, setOpenModal] = useState(false);
  const [saleToDisplay, setSaleToDisplay] = useState<SaleHistory | null>(null);
  const { user } = useAuthContext();

  const RenderPaymentTypeBadge = (paymentType: string) => {
    return (
      <div key={paymentType} className={styles.badge}>
        <span>{paymentType}</span>
      </div>
    );
  };

  const getPaymentTypes = (sale: SaleHistory) => {
    const paymentTypes: React.ReactNode[] = [];
    if (sale.cashAmount > 0) {
      paymentTypes.push(RenderPaymentTypeBadge("Cash"));
    }
    if (sale.creditCardAmount > 0) {
      paymentTypes.push(RenderPaymentTypeBadge("CB"));
    }
    if (sale.giftCertificatesAmount > 0) {
      paymentTypes.push(RenderPaymentTypeBadge("Bon d'achat"));
    }
    if (sale.vouchersAmount > 0) {
      paymentTypes.push(RenderPaymentTypeBadge("Avoir"));
    }
    if (sale.giftcardsAmount > 0) {
      paymentTypes.push(RenderPaymentTypeBadge("Carte cadeau"));
    }

    return paymentTypes.length > 0 ? paymentTypes : "Aucun";
  };

  if (data.length === 0)
    return <EmptyState label="Aucune vente pour cette période" />;

  // add store name to header at index 4 if showStoreName is true
  const finalHeader: ColumnType[] = showStoreName
    ? [
        ...tableHeader.slice(0, 4),
        {
          type: "text",
          key: "storeName",
          label: "Boutique",
        },
        ...tableHeader.slice(4),
      ]
    : tableHeader;

  return (
    <>
      <Table>
        <TableHeader columns={finalHeader} />
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow
                key={row.id}
                onClick={() => {
                  setOpenModal(true);
                  setSaleToDisplay(row);
                }}
              >
                <TableCell>{row.ticketNumber}</TableCell>
                <TableCell>
                  {moment(row.date, "YYYYMMDDHHmmss").format("DD/MM/YYYY")}
                </TableCell>
                <TableCell>
                  {moment(row.date, "YYYYMMDDHHmmss").format("HH:mm")}
                </TableCell>
                <TableCell>{saleTypesObj[row.type]}</TableCell>
                {showStoreName && (
                  <TableCell>{row?.storeName || "-"}</TableCell>
                )}
                <TableCell>{row.firstname + " " + row.lastname}</TableCell>
                <TableCell>
                  {row.sellerFirstname + " " + row.sellerLastname}
                </TableCell>
                <TableCell>{row.deskId}</TableCell>
                <TableCell>{row.items}</TableCell>
                <TableCell>
                  {row.discountApplied > 0
                    ? parseFloat2(row.discountApplied).toFixed(2)
                    : row.itemsAverageDiscount > 0
                    ? parseFloat2(row.itemsAverageDiscount).toFixed(2)
                    : 0}
                  %
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    {getPaymentTypes(row)}
                  </div>
                </TableCell>
                <TableCell
                  style={{
                    fontSize: 16,
                  }}
                >
                  <b>
                    {row.paidAmount} {user.currency}
                  </b>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        type="sidePanel"
      >
        <SaleModal sale={saleToDisplay} />
      </Modal>
    </>
  );
}

export default SalesHistoryTable;
