import { CSSProperties, ReactNode } from "react";
import styles from "./Button.module.css";
import clsx from "clsx";

type ButtonProps = {
  onClick?: () => void;
  label?: string;
  disabled?: boolean;
  variant?: "dark" | "light" | "destructive" | "tertiary";
  type?: "submit" | "reset" | "button";
  children?: ReactNode;
  style?: CSSProperties;
};

export default function Button({
  onClick,
  label,
  disabled,
  variant = "dark",
  type = "button",
  children,
  style,
}: ButtonProps) {
  return (
    <button
      onClick={onClick}
      className={clsx(styles.container, {
        [styles.disabled]: disabled,
        [styles.dark]: variant === "dark",
        [styles.light]: variant === "light",
        [styles.destructive]: variant === "destructive",
        [styles.tertiary]: variant === "tertiary",
      })}
      disabled={disabled}
      type={type}
      style={style}
    >
      {label}
      {children}
    </button>
  );
}
