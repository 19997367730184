import styles from "./PlusPopoverItem.module.css";

type PlusPopoverItemProps = {
  icon: string;
  title: string;
  label: string;
  onClick: () => void;
};

export default function PlusPopoverItem({
  icon,
  title,
  label,
  onClick,
}: PlusPopoverItemProps) {
  return (
    <button className={styles.popoverItemContainer} onClick={onClick}>
      <div className={styles.itemIcon}>
        <img src={icon} alt="icon" />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.title}>{title}</p>
        <p className={styles.label}>{label}</p>
      </div>
    </button>
  );
}
