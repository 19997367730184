import { useAuthContext } from "@providers/AuthContext";
import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

export function AuthGate() {
  const { user, isAuthenticated, isLoading } = useAuthContext();
  const authedPath = useMemo(
    () =>
      user?.currentStoreId
        ? "/store/" + user.currentStoreId + "?desk=" + user.currentDeskNumber
        : "/",
    [user]
  );

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to={authedPath} replace />;
}
