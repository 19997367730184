import { useState } from "react";
import styles from "./page.module.css";
import Button from "@components/Button/Button";
import TextField from "@components/TextField/TextField";
import Modal from "@components/Modal/Modal";
import { useGetCustomers } from "@api/customers/useGetCustomers";
import CustomersTable from "./components/CustomersTable";
import Skeleton from "@components/Loader/Skeleton";
import ErrorState from "@components/Empty/ErrorState";
import Pagination from "@components/Pagination/Pagination";

export default function CustomersPage() {
  const [search, setSearch] = useState("");
  const [querySearch, setQuerySearch] = useState("");
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const { data, isLoading, isError } = useGetCustomers({
    search: querySearch,
    page,
  });

  if (isError) return <ErrorState />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div style={{ flex: 2 }}>
            <h1>
              <b>Clients</b>
            </h1>
          </div>
          <div className={styles.search} style={{ flex: 1.5 }}>
            <TextField
              value={search}
              onChange={setSearch}
              leftIconSrc="/icons/search-lg.svg"
              placeholder="Rechercher par email ou telephone"
              style={{ flex: 1.6 }}
            />
            <Button
              style={{ flex: 1 }}
              onClick={() => {
                setQuerySearch(search);
                setPage(1);
              }}
            >
              Rechercher
            </Button>
          </div>
        </div>
        <div className={styles.tableContainer}>
          {isLoading ? (
            <Skeleton variant="rounded" height={600} />
          ) : (
            <>
              <CustomersTable customers={data.data} />
              <Pagination
                currentPage={page}
                totalPages={data?.pages?.length || 1}
                onChange={setPage}
              />
            </>
          )}
        </div>
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <div>Hello</div>
        </Modal>
        <Modal
          isOpen={sidePanelOpen}
          onClose={() => setSidePanelOpen(false)}
          type="sidePanel"
        >
          <div>I am a side panel</div>
        </Modal>
      </div>
    </div>
  );
}
