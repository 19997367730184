import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { storesKeys } from "./store.keys";
import { Store } from "@pxp-caisse/shared/types/store";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { AxiosResponse } from "axios";

const fetchStores = async (): Promise<Store[]> => {
  const { data }: AxiosResponse<Store[]> = await api.get("/stores");
  return data;
};

type QueryFnType = typeof fetchStores;

export const useStores = () => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: storesKeys.stores(),
    queryFn: fetchStores,
  });
};
