import api from "@api/api";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";

const checkSignatureFile = async (
  file: File
): Promise<{ success: boolean; message: string; verified: boolean }> => {
  const formData = new FormData();
  formData.append("archives", file);

  const {
    data,
  }: AxiosResponse<{ success: boolean; message: string; verified: boolean }> =
    await api.post("/archives/checkSignature", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  return data;
};

type MutationFnType = typeof checkSignatureFile;

export const useCheckSignatureFile = () => {
  return useMutation<ExtractFnReturnType<MutationFnType>, Error, File>({
    mutationFn: checkSignatureFile,
  });
};
