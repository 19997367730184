import api from "@api/api";
import { productsKeys } from "./queryKeys";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { Product } from "@pxp-caisse/shared/types/products";

type StocksParams = {
  reference: string;
  color: string;
};

const getStocks = async (params: StocksParams) => {
  const { data }: AxiosResponse<{ status: number; products: Product[] }> =
    await api.get("/products/stocks", { params });
  return data.products;
};

type QueryFnType = typeof getStocks;

export const useGetStocks = (
  { reference, color }: { reference: string; color: string },
  enabled: boolean
) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: productsKeys.getStocks(reference, color),
    queryFn: () => getStocks({ reference, color }),
    enabled,
  });
};
