import AlertBox from "@components/Alert/AlertBox";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import TextField from "@components/TextField/TextField";

type ForceCloseModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onForce: () => void;
  justificationValue: string;
  onJustificationChange: (justification: string) => void;
};

function ForceCloseModal({
  isOpen,
  onClose,
  onForce,
  justificationValue,
  onJustificationChange,
}: ForceCloseModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} width={"50vw"}>
      <AlertBox
        type="error"
        message="Etes-vous certain de vouloir forcer la validation de ce fonds de caisse ? Vous pouvez laisser un justificatif ci-dessous."
      />
      <TextField
        multiline
        value={justificationValue}
        onChange={onJustificationChange}
      />
      <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
        <Button variant="light" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="destructive" onClick={onForce}>
          Forcer la clôture de caisse
        </Button>
      </div>
    </Modal>
  );
}

export default ForceCloseModal;
