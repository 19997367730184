import { useAuthStore } from "@hooks/useAuthStore.hook";
import { useAuthContext } from "@providers/AuthContext";
import Axios, { InternalAxiosRequestConfig } from "axios";

console.log("process.env.REACT_APP_API_URL", process.env.REACT_APP_API_URL);

const api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

export const useAxiosInterceptors = () => {
  const { setToken } = useAuthStore((state) => state);
  const { logout } = useAuthContext();

  api.interceptors.request.use(function (config) {
    const token = useAuthStore.getState().token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest: CustomAxiosRequestConfig | undefined =
        error.config;

      if (
        error.response?.status === 401 &&
        originalRequest &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;

        try {
          const refreshResponse = await api.post("auth/refreshToken", {
            withCredentials: true,
          });

          const newToken: string = refreshResponse.data.accessToken;
          api.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
          originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
          setToken(newToken);
          return api(originalRequest);
        } catch (refreshError) {
          logout();
          console.error("Token refresh failed", refreshError);
        }
      }

      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return Promise.reject(error);
    }
  );
};

export default api;
