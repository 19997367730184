import clsx from "clsx";
import styles from "./HorizontalTab.module.css";
import { SelectedOption, SelectValue } from "../Select/Select";

export default function HorizontalTab({
  label,
  options,
  selected,
  onChange,
  variant = "normal",
}: {
  label?: string;
  options: SelectedOption[];
  selected: SelectValue;
  onChange: (value: SelectValue) => void;
  variant?: "normal" | "small";
}) {
  return (
    <div
      className={clsx(styles.container, {
        [styles.small]: variant === "small",
      })}
    >
      {label && <label className={styles.label}>{label}</label>}

      <div className={styles.tabContainer}>
        {options.map((option) => {
          return (
            <button
              className={clsx(styles.tab, {
                [styles.selected]: selected === option.value,
              })}
              key={option.value}
              onClick={() => onChange(option.value)}
            >
              {option.name}
            </button>
          );
        })}
      </div>
    </div>
  );
}
