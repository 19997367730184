import api from "@api/api";
import { salesManagementKeys, SaleGiftCardsParams } from "./queryKeys";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { GiftCard } from "@pxp-caisse/shared/types/giftCard.type";

const getSalesGiftCards = async (
  params: SaleGiftCardsParams
): Promise<GiftCard[]> => {
  const { data }: AxiosResponse<GiftCard[]> = await api.get(
    "/sales/management/gift-cards",
    {
      params,
    }
  );
  return data;
};

type QueryFnType = typeof getSalesGiftCards;

export const useGetSalesGiftCards = (params: SaleGiftCardsParams) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: salesManagementKeys.giftCards(params),
    queryFn: () => getSalesGiftCards(params),
  });
};
