import axios from "axios";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getMe } from "@api/auth/me";
import { UserReturnType } from "@pxp-caisse/shared/types/user";

interface IAuthContext {
  user: UserReturnType;
  fetchUser: () => void;
  isAuthenticated?: boolean;
  isLoading: boolean;
  isBooting: boolean;
  isError: boolean;
  setUser: (user: UserReturnType) => void;
  setToken: (token: string) => void;
  logout: () => void;
  token: string | null;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserReturnType | null>(null);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isBooting, setIsBooting] = useState(true);
  const [isError, setIsError] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getMe();
      setUser(data);
      setIsAuthenticated(true);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        setIsAuthenticated(false);
        setUser(null);
      } else {
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
      setTimeout(() => setIsBooting(false), 1500);
    }
  }, []);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    setToken(null);
  }, []);

  useEffect(() => {
    fetchUser().catch(console.error);
  }, [fetchUser]);

  const value = useMemo(
    () => ({
      user: user,
      isAuthenticated,
      isLoading,
      isBooting,
      isError,
      token,
      fetchUser,
      setUser,
      setToken,
      logout,
    }),
    [
      user,
      isAuthenticated,
      isLoading,
      isBooting,
      isError,
      token,
      fetchUser,
      logout,
      setToken,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider, useAuthContext };
