import { useState } from "react";
import HorizontalTab from "@components/HorizontalTab/HorizontalTab";
import styles from "./page.module.css";
import { SelectValue } from "@components/Select/Select";

const HelpTab = () => {
  return (
    <div className={styles.tab}>
      <h3>Installation de la Caisse</h3>

      <div className={styles.sectionContainer}>
        <section>
          <h4>PXPR Extension</h4>

          <p>
            <span>
              Aller sur :{" "}
              <a
                href="https://chrome.google.com/webstore/detail/nbidplblelncenimepaeopjdlbafmgkk/"
                rel="noreferrer"
                target="_blank"
              >
                https://chrome.google.com/webstore/detail/nbidplblelncenimepaeopjdlbafmgkk/
              </a>
            </span>
            <br />
            <br />
            Cliquer sur “Add to Chrome”
          </p>
        </section>

        <section>
          <h4>Le lecteur de code-barres</h4>

          <p>
            Brancher le lecteur à un port USB de l’ordinateur
            <br />
            <br />
            Scanner le code “Keyboard country - France” (0005009) situé sur la
            notice du lecteur
          </p>
        </section>

        <section>
          <h4>L'imprimante</h4>

          <p>
            Brancher l’imprimante à un port USB de l’ordinateur
            <br />
            <br />
            Ne connecter qu'une seule imprimante à l'ordinateur. Ne pas
            connecter plusieurs imprimantes.
          </p>
        </section>

        <section>
          <h4>Le tiroir caisse</h4>

          <p>Brancher le tiroir-caisse à un port USB de l’ordinateur</p>
        </section>

        <section>
          <h4>PXPR Extension Host</h4>

          <p>
            Suivre les instructions indiquées dans ce{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/document/d/1NUw9sO5VKmWEa-UTfAH-ObC8EAHyUzivneiXXhW5OXU/edit?usp=sharing"
            >
              document
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

const PrinterTab = () => {
  return (
    <div className={styles.tab}>
      <h3>Aide aux problèmes d'imprimante</h3>

      <div className={styles.sectionContainer}>
        <section>
          <p>
            Pour commencer, vérifiez que votre imprimante est correctement
            branchée à votre ordinateur, et que celle-ci est allumée. Puis, dans
            la barre de recherche windows, recherchez "imprimantes et scanners"
            et cliquez dessus.
          </p>

          <img src="/images/step0.png" alt="Step 0" />
        </section>

        <section>
          <h4>Étape 1</h4>

          <p>
            Si vous voyez votre imprimante (Souvent appellée EPSON TM-88)
            cliquez sur le bouton "Gérer". Sinon passez à l'étape 2.
            <ul>
              <li>
                Vérifiez que votre imprimante est votre imprimante par défaut,
                si ce n'est pas le cas cliquez sur le bouton "Par défaut".
              </li>
              <li>
                Essayez d'imprimer une page de test, si aucun papier ne sort,
                vous pouvez passez à l'étape 2.
              </li>
            </ul>
          </p>
        </section>

        <section>
          <h4>Étape 2</h4>

          <p>
            Dans la fenêtre "Imprimantes et scanners" lancez une recherche
            d'imprimante, puis ajoutez une imprimante manuellement (cliquez sur
            "Je ne trouve pas mon imprimante dans la liste").
          </p>

          <div className={styles.stepContainer}>
            <img src="/images/step1.png" alt="Step 1" />
          </div>

          <p>
            Séléctionnez la dernière option ("Ajouter une imprimante locale")
            puis cliquez sur suivant.
          </p>

          <div className={styles.stepContainer}>
            <img src="/images/step2.png" alt="Step 2" />
          </div>

          <p>
            <strong>Choisissez le port ESDPRT 001.</strong> Puis cliquez sur
            suivant.
          </p>

          <div className={styles.stepContainer}>
            <img src="/images/step3.png" alt="Step 3" />
          </div>

          <p>
            A cette étape, il vous est demandé de choisir un pilote. Si aucun
            pilote n'est installé, appellez le support. Sinon choisissez le
            pilote Version 5.12
          </p>

          <div className={styles.stepContainer}>
            <img src="/images/step4.png" alt="Step 4" />
          </div>

          <p>
            Ensuite vous pourrez cliquez sur suivant jusqu'à la fin de
            l'installation. Une fois cela fait, vous pourrez tester l'impression
            (cf étape 1).
          </p>
        </section>

        <section>
          <h4>Étape 3</h4>

          <p>
            Si votre imprimante est bien reconnue, il est possible que le
            problème soit lié à l'extension Google Chrome. Pour commencer
            assurez-vous d'utiliser Google Chrome. Puis :
            <ol>
              <li>
                Se rendre sur le Chrome store :{` `}
                <a
                  href="https://chrome.google.com/webstore/detail/pxpr-extension/nbidplblelncenimepaeopjdlbafmgkk"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://chrome.google.com/webstore/detail/pxpr-extension/nbidplblelncenimepaeopjdlbafmgkk
                </a>
              </li>
              <li>Supprimer l'extension</li>
              <li>Ajouter l'extension</li>
              <li>Fermer Google chrome</li>
              <li>Ré-ouvrir Google chrome</li>
            </ol>
          </p>
        </section>

        <section>
          <p>
            <strong>
              Si après avoir suivis ces étapes, votre imprimante ne fonctionne
              toujours pas, contactez le support.
            </strong>
          </p>
        </section>
      </div>
    </div>
  );
};

export default function HelpPage() {
  const [selectedTab, setSelectedTab] = useState<SelectValue>("help");

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Aide</h2>
        <HorizontalTab
          variant="small"
          options={[
            {
              name: `Aide à l'installation`,
              value: "help",
            },
            {
              name: `Problème d'imprimante`,
              value: "printer",
            },
          ]}
          selected={selectedTab}
          onChange={(value) => setSelectedTab(value)}
        />
      </div>

      {selectedTab === "help" && <HelpTab />}
      {selectedTab === "printer" && <PrinterTab />}
    </div>
  );
}
