export const salesManagementKeys = {
  all: ["salesManagement"] as const,
  giftCards: (data: SaleGiftCardsParams) =>
    [...salesManagementKeys.all, "giftCards", data] as const,
};

export type SaleGiftCardsParams = {
  invoiceKey: string;
};

export type CancelSaleRequestParams = {
  userCode: string;
  justification: string;
  saleId: number;
};

export type UpdatePaymentMethodParams = {
  invoiceKey: string;
  cashAmount: number;
  cardAmount: number;
};
