import { useUpdateCart } from "@api/carts/useUpdateCart";
import { CartType } from "@pxp-caisse/shared/types/cart";
import { Discount } from "@pxp-caisse/shared/types/discount";
import { Product } from "@pxp-caisse/shared/types/products";
import { createContext, useContext, useRef, useState } from "react";

export type ProductDiscount = {
  value: number;
  type: "percent" | "raw";
};

export type CartItem = {
  product: Product;
  quantity: number;
  totalProductPrice: number;
  discount: ProductDiscount;
};

type Cart = {
  cartItems: CartItem[];
  totalPrice: number;
  discount: Discount[] | null;
};

type CartContextType = {
  carts: Cart[];
  currentCart: Cart | null;
  setCurrentCartIndex: (index: number) => void;

  // Loading cart data from db
  loadCarts: (carts: CartType[]) => void;

  // Cart management functions
  addItem: (item: Product) => void;
  removeItem: (item: Product) => void;
  clearItem: (item: Product) => void;

  // Loyoly / Zerosix discount
  applyDiscount: (discount: Discount) => void;
  removeDiscount: (discount: Discount) => void;

  // Product discount, can be set by product and without a client account
  setProductDiscount: (product: Product, discount: ProductDiscount) => void;
};

export const defaultChartContext: CartContextType = {
  carts: [
    {
      cartItems: [],
      totalPrice: 0,
      discount: [],
    },
    {
      cartItems: [],
      totalPrice: 0,
      discount: [],
    },
  ],
  currentCart: null,
  setCurrentCartIndex: () => {},
  loadCarts: () => {},

  addItem: () => {},
  removeItem: () => {},
  clearItem: () => {},
  applyDiscount: () => {},
  removeDiscount: () => {},

  setProductDiscount: () => {},
};

const getProductPrice = (item: CartItem) => {
  const basePrice = item.product.list_price;

  if (item.discount.value === 0) {
    return basePrice;
  }

  if (item.discount.type === "percent") {
    return basePrice * ((100 - item.discount.value) / 100);
  }

  if (item.discount.type === "raw") {
    return Math.max(basePrice - item.discount.value, 0);
  }
};

const CartContext = createContext<CartContextType>(defaultChartContext);

const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const [carts, setCarts] = useState<Cart[]>(defaultChartContext.carts);
  const [currentCartIndex, setCurrentCartIndex] = useState<number | null>(0);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const updateCartMutation = useUpdateCart();

  const currentCart =
    currentCartIndex !== null ? carts[currentCartIndex] : null;

  const updateCartItems = (updatedCartItems: CartItem[]) => {
    if (currentCartIndex === null) return;

    const updatedCart = {
      ...carts[currentCartIndex],
      cartItems: updatedCartItems,
      totalPrice: updatedCartItems.reduce(
        (total, item) => total + getProductPrice(item) * item.quantity,
        0
      ),
    };

    const updatedCarts = [...carts];
    updatedCarts[currentCartIndex] = updatedCart;

    setCarts(updatedCarts);

    // Updating debounce timeout
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      const products = [];

      for (const item of updatedCartItems) {
        for (let index = 0; index < item.quantity; index++) {
          products.push(item.product);
        }
      }

      updateCartMutation.mutate({
        pageId: currentCartIndex + 1,
        products: JSON.stringify(products),
        cancelledProducts: "[]",
      });
    }, 350);
  };

  const setProductDiscount = (product: Product, discount: ProductDiscount) => {
    if (currentCartIndex === null) return;

    const updatedCartItems = [...currentCart.cartItems];

    const itemIndex = updatedCartItems.findIndex(
      (item) => item.product.ean === product.ean
    );

    if (itemIndex !== -1) {
      const item = updatedCartItems[itemIndex];

      updatedCartItems[itemIndex].discount = discount;
      updatedCartItems[itemIndex].totalProductPrice =
        getProductPrice(item) * item.quantity;

      updateCartItems(updatedCartItems);
    }
  };

  const loadCarts = (importedCarts: CartType[]) => {
    if (!importedCarts) {
      return;
    }

    const formattedCarts = importedCarts.map((c: CartType) => {
      const products = JSON.parse(c.products) ?? [];

      // Imported carts data are not grouped by product ean,
      // so we need to do it manually to get the correct quantity value.
      const cartItemsMap: {
        [key: string]: CartItem;
      } = {};
      products.forEach((p: Product) => {
        if (cartItemsMap[p.ean]) {
          cartItemsMap[p.ean].quantity += 1;
        } else {
          cartItemsMap[p.ean] = {
            product: p,
            quantity: 1,
            totalProductPrice: p.list_price,
            discount: {
              value: 0,
              type: "percent",
            },
          };
        }
      });

      const cartItems = Object.values(cartItemsMap);
      return {
        cartItems,
        discount: [],
        totalPrice: cartItems.reduce(
          (total, item) => total + getProductPrice(item) * item.quantity,
          0
        ),
      };
    });

    setCarts(formattedCarts);
  };

  const addItem = (product: Product) => {
    if (currentCart) {
      const updatedCartItems = [...currentCart.cartItems];

      const foundItem = updatedCartItems.find(
        (i) => i.product.ean === product.ean
      );

      if (foundItem) {
        foundItem.quantity += 1;
        foundItem.totalProductPrice =
          foundItem.quantity * getProductPrice(foundItem);
      } else {
        updatedCartItems.push({
          product: product,
          quantity: 1,
          discount: {
            value: 0,
            type: "percent",
          },
          totalProductPrice: product.list_price,
        });
      }

      updateCartItems(updatedCartItems);
    }
  };

  // Removing one instance of a product, reducing quantity by one
  const removeItem = (product: Product) => {
    if (currentCart) {
      const foundItem = currentCart.cartItems.find(
        (i) => i.product.ean === product.ean
      );

      if (!foundItem) {
        return;
      }

      if (foundItem.quantity === 1) {
        clearItem(product);
      } else {
        const updatedCartItems = currentCart.cartItems.map((item) => {
          if (item.product.ean === product.ean) {
            const newQuantity = item.quantity - 1;
            return {
              ...item,
              quantity: newQuantity,
              totalProductPrice: newQuantity * getProductPrice(item),
            };
          } else {
            return item;
          }
        });

        updateCartItems(updatedCartItems);
      }
    }
  };

  // Removing item line in cart
  const clearItem = (product: Product) => {
    if (currentCart) {
      const updatedCartItems = currentCart.cartItems.filter(
        (i) => i.product.ean !== product.ean
      );

      updateCartItems(updatedCartItems);
    }
  };

  const applyDiscount = () => {};
  const removeDiscount = () => {};

  return (
    <CartContext.Provider
      value={{
        currentCart: currentCart,

        // For toggling current cart
        carts: carts,
        setCurrentCartIndex: setCurrentCartIndex,
        loadCarts,

        // Cart management functions
        addItem,
        removeItem,
        clearItem,
        applyDiscount,
        removeDiscount,
        setProductDiscount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;

export const useCart = () => useContext(CartContext);
