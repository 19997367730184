import styles from "./Empty.module.css";

type ErrorStateProps = {
  label?: string;
  width?: string;
};

function ErrorState({
  label = "Une erreur est survenue vueillez réessayer",
  width = "20%",
}: ErrorStateProps) {
  return (
    <div className={styles.container}>
      <img src="/icons/unplugged.svg" alt="empty-state" width={width} />
      <h3>{label}</h3>
    </div>
  );
}

export default ErrorState;
