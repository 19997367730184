import { create } from "zustand";

type AuthState = {
  token?: string;
  setToken: (token: string) => void;
  removeToken: () => void;
};

export const useAuthStore = create<AuthState>()((set) => ({
  token: undefined,

  setToken: (token: string) => set(() => ({ token })),
  removeToken: () => set(() => ({ token: undefined })),
}));
