import clsx from "clsx";
import styles from "./Loader.module.css";

type SpinnerProps = {
  size?: "small" | "medium" | "large";
};

function Spinner({ size = "medium" }: SpinnerProps) {
  return (
    <div className={styles.spinnerContainer}>
      <span className={clsx(styles.spinner, styles[size])}></span>
    </div>
  );
}

export default Spinner;
