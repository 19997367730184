import Select from "@components/Select/Select";
import styles from "./page.module.css";

export default function Footer() {
  return (
    <div className={styles.footer}>
      {/* Language select */}
      <Select
        options={[]}
        style={{ width: "fit-content" }}
        onChange={() => {}}
        value={""}
      />

      <div className={styles.footerColumn}>
        <div className={styles.footerRow}>
          <img src="/icons/phone.svg" alt="Phone" width={16} height={16} />
          <p className={styles.footerLabel}>+33 7 83 40 21 91</p>
        </div>

        <div className={styles.footerRow}>
          <img src="/icons/mail-02.svg" alt="Mail" width={16} height={16} />
          <p className={styles.footerLabel}>
            Support : support.pxp@galadrim.fr
          </p>
        </div>
      </div>
    </div>
  );
}
