import styles from "./DiscountTag.module.css";

export default function DiscountTag({
  tagLabel,
  discountValue,
}: {
  discountValue: number;
  tagLabel: string;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <p className={styles.label}>Remise appliquée</p>
        <div className={styles.tag}>{tagLabel}</div>
      </div>

      <p className={styles.discountLabel}>-{discountValue}%</p>
    </div>
  );
}
