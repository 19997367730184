import api from "@api/api";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { queryClient } from "@providers/ReactQueryClientProvider";
import { customersKeys } from "./queryKeys";

export type UpdateCustomerParams = {
  email: string;
  mobile: string;
  firstName: string;
  lastName: string;
  zipcode?: string;
  birthDate?: Date;
  gender?: 0 | 1;
};

export const updateCustomer = async (
  id: number,
  params: UpdateCustomerParams
) => {
  await api.post("/customers/" + id, params);
};

export const useUpdateCustomer = (id: number) => {
  return useMutation({
    mutationFn: (params: UpdateCustomerParams) => updateCustomer(id, params),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: customersKeys.all,
      });
      toast.success("Utilisateur mis à jour.");
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        const errorMessage: string =
          error.response?.data?.errorMessage || "Une erreur est survenue";
        toast.error(errorMessage);
      }
    },
  });
};
