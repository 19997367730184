import clsx from "clsx";
import styles from "./Loader.module.css";
import { CSSProperties } from "react";

type SkeletonProps = {
  variant: "circular" | "rectangular" | "rounded";
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
};

function Skeleton({ variant, width = "100%", height = "100%" }: SkeletonProps) {
  return (
    <div
      className={clsx(styles.skeletonAnimation, styles[variant])}
      style={{
        width,
        height,
      }}
    ></div>
  );
}

export default Skeleton;
