import Modal from "@components/Modal/Modal";
import styles from "./styles/GiftCertificatesModal.module.css";
import CurrencyInput from "@components/CurrencyInput/CurrencyInput";
import { GiftCertificatesType } from "@pxp-caisse/shared/types/giftCertificates.type";
import { useState } from "react";
import Select from "@components/Select/Select";
import {
  GIFT_CERTIFICATES_TYPES_LABEL_OBJ,
  GIFT_CERTIFICATES_TYPES_OPTIONS,
} from "@pxp-caisse/shared/constants/giftCertificates.const";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@components/Table";
import { toTwoDecimals } from "@pxp-caisse/shared/utils/conversion";
import { useAuthContext } from "@providers/AuthContext";

export type Certificate = {
  id: number;
  amount: number;
  type: GiftCertificatesType;
  quantity: number;
};

type GiftCertificatesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (values: Pick<Certificate, "amount" | "type" | "quantity">) => void;
  onRemove: (id: number) => void;
  certificates: Certificate[];
};

function GiftCertificatesModal({
  isOpen,
  onClose,
  onAdd,
  onRemove,
  certificates,
}: GiftCertificatesModalProps) {
  const [amount, setAmount] = useState(0);
  const [type, setType] = useState<GiftCertificatesType>("CAD");
  const [quantity, setQuantity] = useState(0);

  const { user } = useAuthContext();

  const renderAddCertificateForm = () => {
    return (
      <div className={styles.accountingGiftCertificatesAddFormContainer}>
        <div className={styles.accountingGiftCertificatesAddForm}>
          <div className={styles.accountingGiftCertificatesAddFormBlock}>
            <label>Montant</label>
            <CurrencyInput
              value={amount}
              onChange={setAmount}
              style={{
                borderRadius: "12px 0px 0px 12px",
                flex: 1,
                minWidth: "120px",
                width: "100%",
                flexGrow: 1,
              }}
            />
          </div>
          <div className={styles.accountingGiftCertificatesAddFormBlock}>
            <label>Type</label>
            <Select
              options={GIFT_CERTIFICATES_TYPES_LABEL_OBJ}
              onChange={(type: GiftCertificatesType) => setType(type)}
              value={type}
              placeholder="Type"
              selectStyle={{
                flex: 1,
                borderRadius: 0,
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                width: "100%",
                fontSize: "14px",
              }}
              smallerSides
            />
          </div>
          <div className={styles.accountingGiftCertificatesAddFormBlock}>
            <label>Quantité</label>
            <CurrencyInput
              value={quantity}
              onChange={setQuantity}
              style={{
                borderRadius: "0px 12px 12px 0px",
                flex: 1,
                minWidth: "120px",
                width: "100%",
                flexGrow: 1,
              }}
              currency=""
              step="1"
            />
          </div>
        </div>
        <button
          className={styles.addButton}
          onClick={() => onAdd({ amount, type, quantity })}
        >
          <FontAwesomeIcon icon={faPlus} color="white" />
        </button>
      </div>
    );
  };

  const renderCertificatesList = () => {
    if (!certificates?.length) return null;

    return (
      <div className={styles.accountingGiftCertificatesList}>
        <Table>
          <TableHeader
            columns={[
              {
                type: "text",
                key: "amount",
                label: "Montant",
              },
              {
                type: "text",
                key: "type",
                label: "Type",
              },
              {
                type: "text",
                key: "quantity",
                label: "Quantité",
              },
              {
                type: "text",
                key: "total",
                label: "Total",
              },
              {
                type: "text",
                key: "remove",
                label: "",
              },
            ]}
            style={{
              backgroundColor: "var(--grey-50)",
              borderBottom: "1px solid var(--grey-200)",
              padding: "12px 24px",
            }}
            headerCellStyle={{
              padding: "12px 24px",
              borderBottom: "1px solid var(--grey-200)",
              color: "var(--grey-900)",
              fontFamily: "Gotham",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          />
          <TableBody>
            {certificates.map((certificate) => {
              return (
                <TableRow key={certificate.id} disableHover>
                  <TableCell>{certificate.amount}</TableCell>
                  <TableCell>
                    {GIFT_CERTIFICATES_TYPES_OPTIONS[certificate.type]}
                  </TableCell>
                  <TableCell>{certificate.quantity}</TableCell>
                  <TableCell>
                    {toTwoDecimals(certificate.amount * certificate.quantity)}{" "}
                    {user.currency}
                  </TableCell>
                  <TableCell>
                    <button
                      className={styles.removeButton}
                      onClick={() => onRemove(certificate.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} color="white" />
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <p>
          Total :{" "}
          {toTwoDecimals(
            certificates.reduce(
              (acc, certificate) =>
                acc + certificate.amount * certificate.quantity,
              0
            )
          )}{" "}
          {user.currency}
        </p>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Modifier la liste des chèques cadeaux encaissés"}
    >
      {renderAddCertificateForm()}
      {renderCertificatesList()}
    </Modal>
  );
}

export default GiftCertificatesModal;
