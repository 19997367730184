import clsx from "clsx";
import { useState } from "react";
import styles from "./PasswordTextInput.module.css";

export default function PasswordTextInput({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}

      <div className={styles.inputContainer}>
        <input
          type={showPassword ? "text" : "password"}
          value={value}
          className={clsx(styles.input, { [styles.password]: !showPassword })}
          onChange={(e) => onChange(e.target.value)}
        />

        <img
          src={showPassword ? "/icons/eye-off.svg" : "/icons/eye.svg"}
          width={16}
          height={16}
          className={styles.eyeIcon}
          alt="Eye"
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
    </div>
  );
}
