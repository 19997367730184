"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTwoDecimals = exports.toCents = exports.parseFloat2 = void 0;
const parseFloat2 = function (x) {
    if (typeof x === "string") {
        return Math.round(parseFloat(x) * 100) / 100;
    }
    return Math.round(x * 100) / 100;
};
exports.parseFloat2 = parseFloat2;
const toCents = function (x) {
    if (typeof x === "string") {
        return Math.round(parseFloat(x) * 100);
    }
    return Math.round(x * 100);
};
exports.toCents = toCents;
const toTwoDecimals = function (x) {
    if (typeof x === "string") {
        return (0, exports.parseFloat2)(x).toFixed(2);
    }
    return x.toFixed(2);
};
exports.toTwoDecimals = toTwoDecimals;
