import { Product } from "@pxp-caisse/shared/types/products";
import { createContext, useContext, useState } from "react";

interface GlobalState {
  products: Product[];
}

interface GlobalContextType {
  state: GlobalState;
  updateProducts: (newProducts: Product[]) => void;
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const GlobalProvider = ({ children }) => {
  const [state, setState] = useState({
    products: [],
  });

  const updateProducts = (newProducts: Product[]) => {
    setState((prevState) => ({
      ...prevState,
      products: newProducts,
    }));
  };

  return (
    <GlobalContext.Provider value={{ state, updateProducts }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
