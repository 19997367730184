import DatePicker from "@components/Date/DatePicker";
import styles from "./page.module.css";
import { useGetSalesHistory } from "@api/salesHistory/useGetSalesHistory";
import { useState } from "react";
import moment from "moment";
import SalesHistoryTable from "./components/SalesHistoryTable";
import Skeleton from "@components/Loader/Skeleton";
import { useGetTurnover } from "@api/salesHistory/useGetTurnover";
import TurnoverTable from "./components/TurnoverTable";
import ErrorState from "@components/Empty/ErrorState";
import Pagination from "@components/Pagination/Pagination";

function SalesHistoryPage() {
  const [startDate, setStartDate] = useState<Date | null>(
    moment().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(moment().toDate());
  const [page, setPage] = useState(1);

  const {
    data: salesHistory,
    isLoading,
    isError,
  } = useGetSalesHistory({
    begin: moment(startDate).format("YYYY-MM-DD").toString(),
    end: moment(endDate).format("YYYY-MM-DD").toString(),
    page,
  });

  const {
    data: turnoverData,
    isLoading: isLoadingTurnover,
    isError: isErrorTurnover,
  } = useGetTurnover({
    begin: moment(startDate).format("YYYY-MM-DD").toString(),
    end: moment(endDate).format("YYYY-MM-DD").toString(),
  });

  if (isError || isErrorTurnover) {
    return <ErrorState />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div style={{ flex: 2 }}>
            <h1>
              <b>Historique CA & Tickets</b>
            </h1>
          </div>
          <div className={styles.filters}>
            <DatePicker
              type="dateRange"
              setSelectedEndDate={setEndDate}
              setSelectedStartDate={setStartDate}
              onChange={() => setPage(1)}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <div style={{ display: "grid", gap: 40, width: "100%" }}>
            {isLoadingTurnover ? (
              <Skeleton variant="rounded" height={100} />
            ) : (
              <TurnoverTable data={turnoverData} />
            )}
            {isLoading ? (
              <Skeleton variant="rounded" height={500} />
            ) : (
              <>
                <SalesHistoryTable data={salesHistory.data} />
                <Pagination
                  currentPage={page}
                  totalPages={salesHistory.pages.length || 1}
                  onChange={setPage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SalesHistoryPage;
