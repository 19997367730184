import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "@components/Modal/Modal";
import Button from "@components/Button/Button";
import { TextFieldRhf } from "@components/TextField/TextFieldRhf";
import { useState } from "react";
import Spinner from "@components/Loader/Spinner";
import { RetailCustomer } from "@pxp-caisse/shared/types/customer";
import { useMedia } from "@hooks/useMedia";
import Select from "@components/Select/Select";
import DatePicker from "@components/Date/DatePicker";
import { useUpdateCustomer } from "@api/customers/useUpdateCustomer";

const schema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  email: z
    .string()
    .regex(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
  mobile: z.string().regex(/^\+([0-9]{1,3})[0-9]{9}$|^[0-9]{10}$/),
  zipcode: z
    .string()
    .regex(/^[0-9]{5}$/)
    .optional()
    .nullish(),
});

type FormValues = z.infer<typeof schema>;

type EditCustomerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  customer: RetailCustomer;
};

function EditCustomerModal({
  isOpen,
  onClose,
  customer,
}: EditCustomerModalProps) {
  const { handleSubmit, control } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      firstName: customer.firstName ?? undefined,
      lastName: customer.lastName ?? undefined,
      email: customer.email ?? undefined,
      mobile: customer.mobile ?? undefined,
      zipcode: customer.zipcode ?? undefined,
    },
  });

  const { media } = useMedia();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [info, setInfo] = useState<
    Pick<RetailCustomer, "gender" | "birthDate">
  >({
    gender: customer.gender,
    birthDate: customer.birthDate,
  });

  const updateCustomerMutation = useUpdateCustomer(customer.id);

  const onSubmit = (data: FormValues) => {
    setIsSubmitting(true);
    updateCustomerMutation.mutate(
      {
        email: data.email,
        mobile: data.mobile,
        firstName: data.firstName,
        lastName: data.lastName,
        zipcode: data.zipcode,
        birthDate: info.birthDate,
        gender: info.gender,
      },
      {
        onSuccess: () => {
          onClose();
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Modifier la fiche du client"}
      width={media.md ? "50vw" : "100%"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
            borderBottom: "1px solid var(--grey-200)",
            paddingBottom: 20,
            marginTop: 20,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: media.md ? "row" : "column",
              gap: 20,
            }}
          >
            <div style={{ width: "100%", display: "grid", gap: 10 }}>
              <label
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#475468",
                }}
              >
                Genre
              </label>
              <Select
                options={[
                  { name: "Homme", value: 0 },
                  { name: "Femme", value: 1 },
                ]}
                onChange={(value) =>
                  setInfo({ ...info, gender: value as unknown as 0 | 1 })
                }
                value={info.gender}
                placeholder="Genre"
              />
            </div>
            <div style={{ width: "100%", display: "grid", gap: 10 }}>
              <label
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#475468",
                }}
              >
                Genre
              </label>
              <DatePicker
                type="date"
                setSelectedDate={(date: Date) =>
                  setInfo({ ...info, birthDate: date })
                }
                defaultValue={customer.birthDate}
                selectedByDefault={!!customer.birthDate}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: media.md ? "row" : "column",
              gap: 20,
            }}
          >
            <TextFieldRhf
              showError={false}
              label="Prénom"
              control={control}
              placeholder="Prénom"
              name="firstName"
            />
            <TextFieldRhf
              showError={false}
              label="Nom"
              control={control}
              placeholder="Nom"
              name="lastName"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: media.md ? "row" : "column",
              gap: 20,
            }}
          >
            <TextFieldRhf
              showError={false}
              label="Téléphone"
              control={control}
              placeholder="Téléphone"
              name="mobile"
            />
            <TextFieldRhf
              showError={false}
              label="Email"
              control={control}
              placeholder="Email"
              name="email"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: media.md ? "row" : "column",
              gap: 20,
            }}
          >
            <TextFieldRhf
              showError={false}
              label="Code postal"
              control={control}
              placeholder="Code postal"
              name="zipcode"
            />
            <div style={{ width: "100%" }} />
          </div>
        </div>
        <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
          {!isSubmitting ? (
            <>
              <Button variant="light" onClick={onClose} type="button">
                Annuler
              </Button>
              <Button variant="dark" type="submit">
                Valider
              </Button>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </form>
    </Modal>
  );
}

export default EditCustomerModal;
