import { useState } from "react";
import FileInput from "@components/FileInput/FileInput";
import Button from "@components/Button/Button";
import styles from "./page.module.css";
import { useCheckSignatureFile } from "@api/archives/useCheckSignature";

export default function CheckSignaturePage() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { mutate: checkSignature, data, error } = useCheckSignatureFile();

  const handleOnFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  const handleCheckSignature = () => {
    if (selectedFile) {
      checkSignature(selectedFile);
    }
  };

  return (
    <div className={styles.form}>
      <h1 className={styles.title}>Vérification d'archives</h1>

      <FileInput onFileSelect={handleOnFileSelect} />

      <Button onClick={handleCheckSignature} disabled={!selectedFile}>
        Vérifier la signature
      </Button>

      {error && <p className={styles.error}>Erreur: {error.message}</p>}
      {data && (
        <p className={data.verified ? styles.success : styles.error}>
          {data.message}
        </p>
      )}
    </div>
  );
}
