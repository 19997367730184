import api from "@api/api";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";

export type UpdateCartParams = {
  pageId: number;
  products: string;
  cancelledProducts: string;
};

export const updateCustomer = async (params: UpdateCartParams) => {
  await api.post("/carts/update", params);
};

export const useUpdateCart = () => {
  return useMutation({
    mutationFn: (params: UpdateCartParams) => updateCustomer(params),
    onError: (error) => {
      if (isAxiosError(error)) {
        const errorMessage: string =
          error.response?.data?.errorMessage || "Une erreur est survenue";
        toast.error(errorMessage);
      }
    },
  });
};
