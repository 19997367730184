import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ConnectionPage from "./pages/connection/page";
import ProtectedRoute from "@components/ProtectedRoute/ProtectedRoute";
import { AuthGate } from "pages/connection/AuthGate";
import { useAxiosInterceptors } from "@api/api";
import { useAuthContext } from "@providers/AuthContext";
import LoadingPage from "pages/common/LoadingPage";
import CheckSignaturePage from "pages/checkSignature/page";
import Layout from "pages/home/layout";
import RetailPage from "pages/home/tabs/retail/page";
import CustomerPage from "pages/home/tabs/customers/CustomerPage";
import SalesHistoryPage from "pages/home/tabs/sales/page";
import CustomersPage from "pages/home/tabs/customers/page";
import HelpPage from "pages/home/tabs/help/page";
import { GlobalProvider } from "@providers/GlobalProvider";
import CloseDeskPage from "pages/home/tabs/close/page";
import { useEffect } from "react";

function App() {
  useAxiosInterceptors();
  const { isLoading, isBooting } = useAuthContext();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.weglot.com/weglot.min.js";
    script.async = true;
    script.onload = () => {
      if ((window as any).Weglot) {
        (window as any).Weglot.initialize({
          api_key: process.env.REACT_APP_WEGLOT_API_KEY,
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  if (isLoading || isBooting) return <LoadingPage />;

  return (
    <GlobalProvider>
      <Router>
        <Routes>
          <Route element={<AuthGate />}>
            <Route path="/" element={<ConnectionPage />} />
          </Route>
          <Route
            path="/archives/checkSignature"
            element={<CheckSignaturePage />}
          />
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout withOutlet />}>
              <Route path="/store/:id" element={<RetailPage />} />
              <Route path="/sales" element={<SalesHistoryPage />} />
              <Route path="/customers">
                <Route index element={<CustomersPage />} />
                <Route path=":customerId" element={<CustomerPage />} />
              </Route>
              <Route path="/help" element={<HelpPage />} />
              <Route path="/close" element={<CloseDeskPage />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Routes>
      </Router>
    </GlobalProvider>
  );
}

export default App;
