export const customersKeys = {
  all: ["customers"] as const,
  list: (data: CustomerListParams) =>
    [...customersKeys.all, "list", data] as const,
  show: (params: CustomerShowParams) =>
    [...customersKeys.all, "show", params] as const,
};

export type CustomerListParams = {
  search?: string;
  page?: number;
};

export type CustomerShowParams = {
  id: number;
  all?: boolean;
};
