import styles from "./CartItemTable.module.css";

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@components/Table";
import { ColumnType } from "@components/Table/TableHeader";
import { useAuthContext } from "@providers/AuthContext";
import { CartItem, useCart } from "@providers/CartProvider";
import QuantitySelector from "./QuantitySelector/QuantitySelector";
import DiscountInput from "../DiscountInput/DiscountInput";

const tableHeader: ColumnType[] = [
  {
    type: "text",
    key: "reference",
    label: "REF",
  },
  {
    type: "text",
    key: "quantity",
    label: "Quantité",
  },
  {
    type: "text",
    key: "discount_percent",
    label: "Remise en %",
  },
  {
    type: "text",
    key: "discount",
    label: "Remise en €",
  },
  {
    type: "text",
    key: "list_price",
    label: "Prix Unitaire",
  },
  {
    type: "text",
    key: "total_price",
    label: "Prix Total",
  },
  {
    type: "action",
    key: "clear",
    ActionComponent: <div></div>,
  },
];

type CartItemTableProps = {
  data: CartItem[];
};

function CartItemTable({ data }: CartItemTableProps) {
  const { user } = useAuthContext();
  const { addItem, removeItem, clearItem, setProductDiscount } = useCart();

  const tableCellStyle = {
    padding: "8px 16px",
    borderBottom: "1px solid var(--grey-200)",
  };

  return (
    <Table
      style={{
        border: "0px",
        borderRadius: "0px",
        width: "100%",
      }}
    >
      <TableHeader
        columns={tableHeader}
        style={{
          backgroundColor: "var(--grey-50)",
        }}
        headerCellStyle={{
          padding: "12px 24px",
          borderBottom: "1px solid var(--grey-200)",
          color: "var(--grey-900)",
          fontFamily: "Gotham",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
        }}
        sticky
      />
      <TableBody>
        {data.map((item) => {
          return (
            <TableRow disableHover key={item.product.ean}>
              <TableCell style={tableCellStyle}>
                <div className={styles.referenceContainer}>
                  <img
                    src={
                      item.product.imageURL === "/img/defaultProduct.png"
                        ? "/images/default_product.png"
                        : item.product.imageURL
                    }
                    alt="Product"
                    width={90}
                  />

                  <div className={styles.referenceColumn}>
                    <p className={styles.referenceLabel}>
                      {item.product.reference}_{item.product.color_reference}_
                      {item.product.label}
                    </p>
                    <p className={styles.name}>{item.product.name}</p>
                  </div>
                </div>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <QuantitySelector
                  value={item.quantity}
                  onDecrement={() => removeItem(item.product)}
                  onIncrement={() => addItem(item.product)}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                <DiscountInput
                  defaultValue={
                    item.discount.type === "percent" ? item.discount.value : 0
                  }
                  onChange={(value) => {
                    setProductDiscount(item.product, {
                      value,
                      type: "percent",
                    });
                  }}
                  type={"percent"}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                <DiscountInput
                  defaultValue={
                    item.discount.type === "raw" ? item.discount.value : 0
                  }
                  onChange={(value) => {
                    setProductDiscount(item.product, {
                      value,
                      type: "raw",
                    });
                  }}
                  type={"raw"}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                <p
                  style={{
                    textAlign: "end",
                    width: "100%",
                  }}
                >
                  {Number(item.product.list_price).toFixed(2)} {user.currency}
                </p>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <p
                  style={{
                    textAlign: "end",
                    width: "100%",
                  }}
                >
                  {Number(item.totalProductPrice).toFixed(2)} {user.currency}
                </p>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <button
                  className={styles.actionContainer}
                  onClick={() => clearItem(item.product)}
                >
                  <div className={styles.actionButton}>
                    <img src="/icons/trash-03.svg" alt="Clear item" />
                  </div>
                </button>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default CartItemTable;
