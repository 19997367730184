"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isProd = exports.isMM = exports.isStaging = exports.isDev = void 0;
const isDev = !!process.env.DEV;
exports.isDev = isDev;
const isStaging = !!process.env.STAGING;
exports.isStaging = isStaging;
const isProd = !isDev && !isStaging;
exports.isProd = isProd;
const isMM = !!process.env.isMM;
exports.isMM = isMM;
