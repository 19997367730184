import React, { CSSProperties } from "react";
import styles from "./CurrencyInput.module.css";

type CurrencyInputProps = {
  value: number;
  onChange: (value: number) => void;
  currency?: string;
  allowNegative?: boolean;
  max?: number;
  style?: CSSProperties;
  step?: string;
  disabled?: boolean;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({
  value,
  onChange,
  currency = "€",
  allowNegative = false,
  max,
  style,
  step = "0.01",
  disabled = false,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ? parseFloat(event.target.value) : 0;
    onChange(newValue);
  };
  return (
    <div className={styles.currencyInput} style={style}>
      <input
        type="number"
        value={value?.toString()}
        onChange={handleChange}
        placeholder="0.00"
        step={step}
        max={max}
        min={allowNegative ? undefined : 0}
        disabled={disabled}
      />
      {!!currency && <span className={styles.currencySymbol}>{currency}</span>}
    </div>
  );
};

export default CurrencyInput;
