"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateOptionsFromLabelObj = exports.generateTypeObjectFromEnum = void 0;
const generateTypeObjectFromEnum = (enumArray) => {
    return Object.fromEntries(enumArray.map((type) => [type, type]));
};
exports.generateTypeObjectFromEnum = generateTypeObjectFromEnum;
const generateOptionsFromLabelObj = (labels) => {
    return Object.entries(labels).map(([k, v]) => ({ value: k, name: v }));
};
exports.generateOptionsFromLabelObj = generateOptionsFromLabelObj;
