import { SaleHistory } from "@pxp-caisse/shared/types/sale";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "@components/Modal/Modal";
import Button from "@components/Button/Button";
import { TextFieldRhf } from "@components/TextField/TextFieldRhf";
import { useCancelSaleRequest } from "@api/salesManagement/useCancelSaleRequest";
import { saleCancelRequestStatusObj } from "@pxp-caisse/shared/constants/sale.const";
import AlertBox from "@components/Alert/AlertBox";
import { useState } from "react";
import Spinner from "@components/Loader/Spinner";

const schema = z.object({
  userCode: z
    .string({ message: "Veuillez renseigner votre code d'utilisateur." })
    .min(1, { message: "Veuillez renseigner votre code d'utilisateur." }),
  justification: z
    .string({ message: "Veuillez renseigner une justification." })
    .min(1, { message: "Veuillez renseigner une justification." }),
});

type FormValues = z.infer<typeof schema>;

type CancelSaleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  sale: SaleHistory;
};

function CancelSaleModal({ isOpen, onClose, sale }: CancelSaleModalProps) {
  const { handleSubmit, control } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      userCode: undefined,
      justification: undefined,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const cancelMutation = useCancelSaleRequest();

  const onSubmit = (data: FormValues) => {
    setIsSubmitting(true);
    cancelMutation.mutate(
      {
        userCode: data.userCode,
        justification: data.justification,
        saleId: sale.id,
      },
      {
        onSuccess: () => {
          onClose();
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      }
    );
  };

  const renderForm = () => {
    const alertBoxMessage =
      sale.cancelRequestStatus === saleCancelRequestStatusObj.REFUSED
        ? "Une demande d'annulation pour cette vente a été refusée. Voulez vous refaire la demande ?"
        : "L'annulation d'une vente requiert l'intervention d'un responsable, responsable-adjoint ou administrateur.\n Merci d'entrer l'un de ces codes pour validation.";
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "40vw",
        }}
      >
        <AlertBox
          type={
            sale.cancelRequestStatus === saleCancelRequestStatusObj.REFUSED
              ? "error"
              : "info"
          }
          message={alertBoxMessage}
        />
        <div
          style={{
            display: "grid",
            gap: 20,
            borderBottom: "1px solid var(--grey-200)",
            paddingBottom: 20,
            marginTop: 20,
          }}
        >
          <TextFieldRhf
            name="userCode"
            control={control}
            placeholder="Code utilisateur"
            label="Code utilisateur"
            type="password"
          />
          <TextFieldRhf
            name="justification"
            control={control}
            placeholder=""
            label="Justification"
            multiline
          />
        </div>
        <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
          {!isSubmitting ? (
            <>
              <Button variant="light" onClick={onClose} type="button">
                Fermer
              </Button>
              <Button variant="destructive" type="submit">
                {sale.cancelRequestStatus === saleCancelRequestStatusObj.REFUSED
                  ? "Refaire une demande"
                  : "Annuler la vente"}
              </Button>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </form>
    );
  };

  const renderPendingOrAccepted = () => {
    if (sale.cancelRequestStatus === saleCancelRequestStatusObj.PENDING) {
      return (
        <>
          <div
            style={{
              display: "grid",
              gap: 20,
              borderBottom: "1px solid var(--grey-200)",
              paddingBottom: 20,
              lineHeight: 2,
              textAlign: "center",
            }}
          >
            <p>
              Une demande d'annulation pour cette vente est en attente de
              validation.
            </p>
          </div>
          <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
            <Button variant="light" onClick={onClose} type="button">
              Fermer
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div
          style={{
            display: "grid",
            gap: 20,
            borderBottom: "1px solid var(--grey-200)",
            paddingBottom: 20,
            lineHeight: 2,
            textAlign: "center",
          }}
        >
          <p>Cette vente a déjà été annulée.</p>
        </div>
        <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
          <Button variant="light" onClick={onClose} type="button">
            Fermer
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Annuler la vente n°" + sale.ticketNumber}
    >
      {!sale.cancelRequestStatus ||
      sale.cancelRequestStatus === saleCancelRequestStatusObj.REFUSED
        ? renderForm()
        : renderPendingOrAccepted()}
    </Modal>
  );
}

export default CancelSaleModal;
