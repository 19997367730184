import api from "@api/api";
import { cartsKeys } from "./queryKeys";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { CartType } from "@pxp-caisse/shared/types/cart";

const getCarts = async (): Promise<CartType[]> => {
  const { data }: AxiosResponse = await api.get("/carts");
  return data.carts;
};

type QueryFnType = typeof getCarts;

export const useGetCarts = () => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: cartsKeys.getCarts(),
    queryFn: () => getCarts(),
  });
};
