import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@components/Table";
import { ColumnType } from "@components/Table/TableHeader";
import { useAuthContext } from "@providers/AuthContext";
import { Turnover } from "@pxp-caisse/shared/types/sale";
import styles from "../page.module.css";

const tableHeader: ColumnType[] = [
  {
    type: "text",
    key: "cash",
    label: "Cash",
  },
  {
    type: "text",
    key: "creditCard",
    label: "CB",
  },
  {
    type: "text",
    key: "avoirs",
    label: "Bons d'achat/Avoirs utilisés",
  },
  {
    type: "text",
    key: "edited",
    label: "Bons d'achat/Avoirs édités",
  },
  {
    type: "text",
    key: "giftCertificatesAmount",
    label: "Chèques cadeau",
  },
  {
    type: "text",
    key: "giftCardsAmount",
    label: "Cartes cadeau",
  },
  {
    type: "text",
    key: "onlineReturnAmount",
    label: "Avoirs achat en ligne",
  },
  {
    type: "text",
    key: "total",
    label: "Total CA",
  },
];

type TurnoverTableProps = {
  data: Turnover;
};

function TurnoverTable({ data }: TurnoverTableProps) {
  const { user } = useAuthContext();

  const tableCellStyle = {
    padding: "0px 25px 10px 25px",
  };

  return (
    <>
      <Table>
        <TableHeader
          columns={tableHeader}
          style={{
            background: "white",
            color: "#959cab",
            fontSize: 12,
          }}
          headerCellStyle={{
            padding: "10px 25px 0px 25px",
          }}
        />
        <TableBody>
          <TableRow disableHover>
            <TableCell className={styles.turnoverCell} style={tableCellStyle}>
              {data.cashTurnover} {user.currency}
            </TableCell>
            <TableCell className={styles.turnoverCell} style={tableCellStyle}>
              {data.ccTurnover} {user.currency}
            </TableCell>
            <TableCell className={styles.turnoverCell} style={tableCellStyle}>
              {data.vouchersTurnover} {user.currency}
            </TableCell>
            <TableCell className={styles.turnoverCell} style={tableCellStyle}>
              {data.vouchersCreated} {user.currency}
            </TableCell>
            <TableCell className={styles.turnoverCell} style={tableCellStyle}>
              {data.giftCertificatesTurnover} {user.currency}
            </TableCell>
            <TableCell className={styles.turnoverCell} style={tableCellStyle}>
              {data.giftcardsTurnover} {user.currency}
            </TableCell>
            <TableCell className={styles.turnoverCell} style={tableCellStyle}>
              {data.onlineReturnTurnover} {user.currency}
            </TableCell>
            <TableCell className={styles.turnoverCell} style={tableCellStyle}>
              {data.totalTurnover} {user.currency}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

export default TurnoverTable;
