import { useEffect, useState } from "react";
import styles from "./DiscountInput.module.css";

type DiscountInputProps = {
  defaultValue: number;
  onChange: (value: number) => void;
  type: "percent" | "raw";
};

export default function DiscountInput({
  defaultValue,
  onChange,
  type,
}: DiscountInputProps) {
  const [value, setValue] = useState(defaultValue.toFixed(2));

  useEffect(() => {
    setValue(defaultValue.toFixed(2));
  }, [defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleBlur = () => {
    let parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      parsedValue = 0;
    }

    if (type === "percent" && (parsedValue < 0 || parsedValue > 100)) {
      parsedValue = 0;
    }

    if (type === "raw" && parsedValue < 0) {
      parsedValue = 0;
    }

    setValue(parsedValue.toFixed(2));
    onChange(parsedValue);
  };

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        onChange={handleChange}
        value={value}
        onBlur={handleBlur}
      />
      <div className={styles.icon}>{type === "percent" ? "%" : "€"}</div>
    </div>
  );
}
