import { useState, useEffect } from "react";

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export function useMedia() {
  const getMatches = () => {
    const width = window.innerWidth;
    return {
      sm: width >= breakpoints.sm,
      md: width >= breakpoints.md,
      lg: width >= breakpoints.lg,
      xl: width >= breakpoints.xl,
      "2xl": width >= breakpoints["2xl"],
    };
  };

  const [media, setMedia] = useState(getMatches);

  useEffect(() => {
    const handleResize = () => setMedia(getMatches);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { media };
}
