import AlertBox from "@components/Alert/AlertBox";
import Modal from "@components/Modal/Modal";
import { FloatErrorsType } from "@pxp-caisse/shared/types/accounting";
import styles from "./styles/ErrorModal.module.css";
import { useMedia } from "@hooks/useMedia";
import { useAuthContext } from "@providers/AuthContext";
import { parseFloat2 } from "@pxp-caisse/shared/utils/conversion";
import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
} from "@components/Table";
import { GIFT_CERTIFICATES_TYPES_OPTIONS } from "@pxp-caisse/shared/constants/giftCertificates.const";
import Button from "@components/Button/Button";

type CloseErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onForce: () => void;
  errors: FloatErrorsType | undefined;
};

function CloseErrorModal({
  isOpen,
  onClose,
  errors,
  onForce,
}: CloseErrorModalProps) {
  const { media } = useMedia();
  const { user } = useAuthContext();

  const [isCollapsed, setIsCollapsed] = useState({
    theorical: true,
    declared: true,
  });

  const renderSections = () => {
    if (!errors) return null;

    const sections: React.ReactNode[] = [];

    const theoricalCashFloat =
      parseFloat2(errors.lastCashFloatAmount) +
      parseFloat2(errors.cashTurnoverSinceLastFloat);

    const totalCashDeclared =
      parseFloat2(errors.declaredBankAmount) +
      parseFloat2(errors.declaredCashFloat) +
      parseFloat2(errors.declaredCashDisbursement);

    if (theoricalCashFloat !== totalCashDeclared) {
      sections.push(
        <div className={styles.section}>
          <h2>Erreurs de cash</h2>
          <div>
            <div
              className={clsx(styles.declared, styles.trigger)}
              onClick={() =>
                setIsCollapsed({
                  ...isCollapsed,
                  declared: !isCollapsed.declared,
                })
              }
            >
              <p>Total cash déclaré</p>
              <p>
                {totalCashDeclared} {user.currency}
              </p>
            </div>
            <div
              className={clsx(styles.collapsableContent, {
                [styles.collapsed]: isCollapsed.declared,
                [styles.expanded]: !isCollapsed.declared,
              })}
            >
              <div className={clsx(styles.item, styles.red)}>
                <p>Mise en banque déclarée</p>
                <p>
                  {errors?.declaredBankAmount} {user.currency}
                </p>
              </div>
              <div className={clsx(styles.item, styles.red)}>
                <p>Sortie de caisse déclarée</p>
                <p>
                  {errors?.declaredCashDisbursement} {user.currency}
                </p>
              </div>
              <div className={clsx(styles.item, styles.red)}>
                <p>Fonds de caisse déclaré</p>
                <p>
                  {errors?.declaredCashFloat} {user.currency}
                </p>
              </div>
            </div>
          </div>
          <div
            className={clsx(styles.theorical, styles.trigger)}
            style={{
              borderBottom: isCollapsed.theorical
                ? ""
                : "1px solid var(--grey-200)",
            }}
            onClick={() =>
              setIsCollapsed({
                ...isCollapsed,
                theorical: !isCollapsed.theorical,
              })
            }
          >
            <p>Total cash théorique</p>
            <p>
              {theoricalCashFloat} {user.currency}
            </p>
          </div>
          <div
            className={clsx(styles.collapsableContent, {
              [styles.collapsed]: isCollapsed.theorical,
              [styles.expanded]: !isCollapsed.theorical,
            })}
          >
            <div className={styles.item}>
              <p>CA cash enregistré depuis le dernier fonds de caisse</p>
              <p>
                {errors?.cashTurnoverSinceLastFloat} {user.currency}
              </p>
            </div>
            <div className={styles.item}>
              <p>
                Dernier fonds de caisse enregistré (
                {errors.lastCashFloatDate &&
                  moment(errors.lastCashFloatDate)
                    .add(-6, "hours")
                    .format("DD/MM/YYYY à HH:mm:ss")}
                )
              </p>
              <p>
                {errors?.lastCashFloatAmount} {user.currency}
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (errors.declaredCcTurnover !== errors.ccTurnoverSinceLastFloat) {
      sections.push(
        <div className={styles.section}>
          <h2>Erreurs de CC</h2>
          <div className={styles.declared}>
            <p>Total CC déclaré</p>
            <p>
              {errors?.declaredCcTurnover} {user.currency}
            </p>
          </div>
          <div className={styles.theorical}>
            <p>Total CC théorique</p>
            <p>
              {errors?.ccTurnoverSinceLastFloat} {user.currency}
            </p>
          </div>
        </div>
      );
    }

    if (
      errors.declaredGiftCertificatesTurnover !==
      errors.giftCertificatesTurnoverSinceLastFloat
    ) {
      sections.push(
        <div className={styles.section}>
          <h2>Erreurs de chèque-cadeau</h2>
          <div className={styles.declared}>
            <p>CA en chèque-cadeau déclaré</p>
            <p>
              {errors?.declaredGiftCertificatesTurnover} {user.currency}
            </p>
          </div>
          <div className={styles.theorical}>
            <p>CA en chèque-cadeau théorique</p>
            <p>
              {errors?.giftCertificatesTurnoverSinceLastFloat} {user.currency}
            </p>
          </div>
        </div>
      );
    }

    if (errors.giftCertificatesDetailError.errorDetected) {
      sections.push(
        <div style={{ marginTop: 20 }}>
          <Table>
            <TableHeader
              columns={[
                {
                  type: "text",
                  key: "amount",
                  label: "Montant",
                },
                {
                  type: "text",
                  key: "type",
                  label: "Type",
                },
                {
                  type: "text",
                  key: "quantity",
                  label: "Quantité déclarée",
                },
                {
                  type: "text",
                  key: "theoricalQuantity",
                  label: "Quantité théorique",
                },
              ]}
              style={{
                background: "white",
                color: "#959cab",
                fontSize: 12,
              }}
              headerCellStyle={{
                padding: "12px 24px",
                borderBottom: "1px solid var(--grey-200)",
                color: "#475468",
                fontFamily: "Gotham",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            />
            <TableBody>
              {errors.giftCertificatesDetailError.comparedGiftCertificates.map(
                (giftCertificateError, index) => {
                  return (
                    <TableRow
                      key={index}
                      disableHover
                      style={{
                        backgroundColor: giftCertificateError.hasError
                          ? "#fce8e7"
                          : undefined,
                      }}
                    >
                      <TableCell>{giftCertificateError.amount}</TableCell>
                      <TableCell>
                        {
                          GIFT_CERTIFICATES_TYPES_OPTIONS[
                            giftCertificateError.type
                          ]
                        }
                      </TableCell>
                      <TableCell>
                        {giftCertificateError.declaredQuantity}
                      </TableCell>
                      <TableCell>
                        {giftCertificateError.theoricalQuantity}
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </div>
      );
    }

    return sections;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} width={media.md ? "50vw" : "100%"}>
      <AlertBox
        type="error"
        message="Une erreur a été détectée lors de la clôture de la caisse"
      />
      <div className={styles.container}>
        {renderSections()}
        <div className={styles.footer}>
          <Button variant="destructive" onClick={onForce}>
            Forcer la clôture de caisse
          </Button>
          <Button variant="dark" onClick={onClose}>
            Modifier les valeurs saisies
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CloseErrorModal;
