import api from "@api/api";
import { salesHistoryKeys, SalesListParams } from "./queryKeys";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { SaleHistory } from "@pxp-caisse/shared/types/sale.type";
import { SeekPaginationResponse } from "@pxp-caisse/shared/types/common.type";

const getSalesHistory = async (
  params: SalesListParams
): Promise<SeekPaginationResponse<SaleHistory>> => {
  const { data }: AxiosResponse<SeekPaginationResponse<SaleHistory>> =
    await api.get("/sales/history", {
      params,
    });
  return data;
};

type QueryFnType = typeof getSalesHistory;

export const useGetSalesHistory = (params: SalesListParams) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: salesHistoryKeys.list(params),
    queryFn: () => getSalesHistory(params),
  });
};
