import { useGetCustomer } from "@api/customers/useGetCustomer";
import { useParams } from "react-router-dom";
import styles from "./page.module.css";
import Button from "@components/Button/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@components/Table";
import { RetailCustomer } from "@pxp-caisse/shared/types/customer";
import moment from "moment";
import Skeleton from "@components/Loader/Skeleton";
import { ColumnType } from "@components/Table/TableHeader";
import { useGetSalesHistory } from "@api/salesHistory/useGetSalesHistory";
import SalesHistoryTable from "../sales/components/SalesHistoryTable";
import { useState } from "react";
import Pagination from "@components/Pagination/Pagination";
import EditCustomerModal from "./components/EditCustomerModal";
import ErrorState from "@components/Empty/ErrorState";

const zeroSixStatusIcons = {
  bronze: "/images/bronze_icon.webp",
  silver: "/images/silver_icon.webp",
  gold: "/images/gold_icon.webp",
  vip: "/images/vip_icon.webp",
};

const tableHeader: ColumnType[] = [
  {
    type: "text",
    key: "customer",
    label: "Client",
  },
  {
    type: "text",
    key: "mobile",
    label: "Téléphone",
  },
  {
    type: "text",
    key: "email",
    label: "Email",
  },
  {
    type: "text",
    key: "birthDate",
    label: "Date de naissance",
  },
  {
    type: "text",
    key: "postalCode",
    label: "Code postal",
  },
  {
    type: "text",
    key: "currentStatusName",
    label: "Infos",
  },
];

const CustomerTable = ({
  customer,
  fidelityInfo,
}: {
  customer: RetailCustomer;
  fidelityInfo?: any;
  availableVouchers?: any;
}) => {
  const tableCellStyle = {
    padding: "0px 25px 10px 25px",
  };

  const fullName = (
    (customer?.firstName ?? "") +
    " " +
    (customer?.lastName ?? "")
  ).trim();

  return (
    <Table>
      <TableHeader
        columns={tableHeader}
        style={{
          background: "white",
          color: "#959cab",
          fontSize: 12,
        }}
        headerCellStyle={{
          padding: "10px 25px 0px 25px",
        }}
      />
      <TableBody>
        <TableRow disableHover>
          <TableCell style={tableCellStyle}>
            {!!fullName ? fullName : "Nom inconnu"}
          </TableCell>
          <TableCell style={tableCellStyle}>{customer?.mobile}</TableCell>
          <TableCell style={tableCellStyle}>{customer?.email ?? "-"}</TableCell>
          <TableCell style={tableCellStyle}>
            {customer?.birthDate
              ? moment(customer.birthDate).format("DD/MM/YYYY")
              : "-"}
          </TableCell>
          <TableCell style={tableCellStyle}>
            {customer?.zipcode ?? "-"}
          </TableCell>
          <TableCell style={tableCellStyle}>
            {fidelityInfo?.currentStatusName ? (
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <img
                  src={
                    zeroSixStatusIcons[
                      fidelityInfo?.currentStatusName?.toLowerCase()
                    ]
                  }
                  alt="status"
                  width={30}
                  height={30}
                />
                <div
                  style={{
                    borderRight: "1px solid var(--grey-200)",
                    paddingRight: 8,
                  }}
                >
                  <b>{fidelityInfo.currentStatusName}</b>
                </div>
                <p>{fidelityInfo.pointsBalance} points</p>
              </div>
            ) : (
              "-"
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

function CustomerPage() {
  const { customerId } = useParams();

  const [page, setPage] = useState(1);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const {
    data: customer,
    isLoading: isCustomerLoading,
    isError,
  } = useGetCustomer({
    id: +customerId,
    all: true,
  });

  const {
    data: salesHistory,
    isLoading: isSalesHistoryLoading,
    isError: isHistoryError,
  } = useGetSalesHistory({
    begin: moment().subtract(10, "years").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    customerId: +customerId,
    page,
  });

  const fullName = (
    (customer?.customer?.firstName ?? "") +
    " " +
    (customer?.customer?.lastName ?? "")
  ).trim();

  if (isError || isHistoryError) {
    return <ErrorState />;
  }

  if (isCustomerLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.header}>
            <div style={{ flex: 1 }}>
              <Skeleton variant="rounded" height={50} width={200} />
            </div>
            <div style={{ flex: 0.2 }}>
              <Skeleton variant="rounded" height={50} width={200} />
            </div>
          </div>
          <div className={styles.tableContainer}>
            <div style={{ display: "grid", gap: 40, width: "100%" }}>
              <Skeleton variant="rounded" height={80} width={"100%"} />
              <Skeleton variant="rounded" height={400} width={"100%"} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div style={{ flex: 1 }}>
            <h1>
              <b>{!!fullName ? fullName : "Nom inconnu"}</b>
            </h1>
          </div>
          <div style={{ flex: 0.2 }}>
            <Button onClick={() => setIsEditModalOpen(true)}>Modifier</Button>
          </div>
        </div>
        <div className={styles.tableContainer}>
          <div style={{ display: "grid", gap: 40, width: "100%" }}>
            {isCustomerLoading ? (
              <Skeleton variant="rounded" height={80} />
            ) : (
              <CustomerTable
                customer={customer.customer}
                fidelityInfo={customer.fidelityInfo}
                availableVouchers={customer.availableVouchers}
              />
            )}
            {isSalesHistoryLoading ? (
              <Skeleton variant="rounded" height={400} />
            ) : (
              <>
                <SalesHistoryTable data={salesHistory?.data} showStoreName />
                <Pagination
                  currentPage={page}
                  totalPages={salesHistory.pages.length || 1}
                  onChange={setPage}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <EditCustomerModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        customer={customer.customer}
      />
    </div>
  );
}

export default CustomerPage;
