import Button from "@components/Button/Button";
import ReferenceField, {
  ReferenceType,
} from "@components/ReferenceField/ReferenceField";
import { SelectValue } from "@components/Select/Select";
import TagSelect from "@components/TagSelect/TagSelect";
import TextField from "@components/TextField/TextField";
import { useGlobalContext } from "@providers/GlobalProvider";
import { FormEvent, useMemo, useState } from "react";
import { useCart } from "../../../../providers/CartProvider";
import DiscountTag from "./DiscountTag/DiscountTag";
import styles from "./page.module.css";
import CartItemTable from "./ProductTable/CartItemTable";

export default function RetailPage() {
  const [clientName, setClientName] = useState("");
  const [barcode, setBarcode] = useState("");
  const { currentCart, addItem } = useCart();

  const { state } = useGlobalContext();

  const [referenceValue, setReferenceValue] = useState<ReferenceType>({
    reference: "",
    color: "",
    size: "",
  });

  const searchProductByReferenceValue = (value: ReferenceType) => {
    return (
      state?.products?.find(
        (p) =>
          p.reference === value.reference &&
          p.color_reference === value.color &&
          p.label === value.size
      ) ?? null
    );
  };

  const searchProductByEan = (ean: string) => {
    return state?.products?.find((p) => p.ean.toString() === ean) ?? null;
  };

  const handleSubmitBarcode = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const product = searchProductByEan(barcode);

    if (product) {
      addItem(product);
    }
  };

  const handleSubmitReference = () => {
    const product = searchProductByReferenceValue(referenceValue);

    if (product) {
      addItem(product);
    }

    setReferenceValue({
      reference: "",
      color: "",
      size: "",
    });
  };

  const handleCashIn = () => {};

  const discountOptions = useMemo(
    () => [
      {
        value: "retailer",
        name: "Commercant",
        discount: 10,
      },
      {
        value: "associate",
        name: "Collaborateur",
        discount: 30,
      },
    ],
    []
  );

  const [selectedDiscount, setSelectedDiscount] = useState<SelectValue>("");
  const currentDiscount = useMemo(() => {
    return discountOptions.find(({ value }) => value === selectedDiscount);
  }, [discountOptions, selectedDiscount]);

  return (
    <div className={styles.twoSidePanel}>
      <CartItemTable data={currentCart?.cartItems ?? []} />

      <div className={styles.checkingPanel}>
        <div className={styles.inputContainer}>
          <div className={styles.header}>
            <TextField
              value={clientName}
              onChange={(value) => setClientName(value)}
              leftIconSrc="/icons/user-03.svg"
              placeholder="Ajouter un client"
            />
          </div>

          <div className={styles.referenceSection}>
            <form className={styles.barcodeForm} onSubmit={handleSubmitBarcode}>
              <TextField
                value={barcode}
                onChange={(value) => setBarcode(value)}
                leftIconSrc="/icons/search-lg.svg"
                placeholder="Code barres"
              />

              <Button style={{ width: "fit-content" }}>
                <img src="/icons/arrow-right.svg" alt="Arrow right" />
              </Button>
            </form>

            <div className={styles.referenceForm}>
              <ReferenceField
                handleChange={(value: ReferenceType) => {
                  setReferenceValue(value);
                }}
                referenceValue={referenceValue}
              />

              <Button label="Ajouter" onClick={handleSubmitReference} />
            </div>
          </div>

          <div className={styles.discountSection}>
            <div className={styles.discoutTagContainer}>
              <h3 className={styles.sectionTitle}>
                Remises (Hors cartes cadeau)
              </h3>

              <Button label="Remise personnalisée" onClick={() => {}} />

              <TagSelect
                options={discountOptions}
                value={selectedDiscount}
                onChange={(value) => setSelectedDiscount(value)}
              />

              {selectedDiscount !== "" && (
                <DiscountTag
                  discountValue={currentDiscount?.discount ?? 0}
                  tagLabel={currentDiscount?.name ?? ""}
                />
              )}
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.labelRow}>
            <label className={styles.totalLabel}>Total à régler</label>
            <p className={styles.priceLabel}>
              {(currentCart?.totalPrice ?? 0 / 100).toFixed(2)} €
            </p>
          </div>

          <Button onClick={handleCashIn} label={"Encaisser"} variant="light" />
        </div>
      </div>
    </div>
  );
}
