import { useEffect } from "react";
import { useStores } from "@api/store/stores.query";
import ConnectionForm from "./ConnectionForm";
import Footer from "./Footer";
import styles from "./page.module.css";

export default function ConnectionPage() {
  const { data, isLoading } = useStores();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "scripts/load-custom-language-switcher.js";
    script.onload = () => {
      console.log("External script loaded");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <main>
      <div className={styles.twoSideRow}>
        <div className={styles.formPanel}>
          <div className={styles.header}>
            <img
              src={"/images/header.png"}
              alt={"Header"}
              width={192}
              height={12}
            />
          </div>

          {!isLoading && <ConnectionForm stores={data ?? []} />}

          <Footer />
        </div>

        <div className={styles.imageContainer}>
          <img
            src={"/images/side-panel.jpeg"}
            alt={"Side panel"}
            className={styles.sidePanelImage}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </main>
  );
}
