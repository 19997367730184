import api from "@api/api";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { CancelSaleRequestParams } from "./queryKeys";
import { salesHistoryKeys } from "@api/salesHistory/queryKeys";
import { queryClient } from "@providers/ReactQueryClientProvider";

export const cancelSaleRequest = async (params: CancelSaleRequestParams) => {
  await api.post("/sales/management/cancel-request/" + params.saleId, {
    userCode: params.userCode,
    justification: params.justification,
  });
};

export const useCancelSaleRequest = () => {
  return useMutation({
    mutationFn: cancelSaleRequest,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: salesHistoryKeys.all,
      });
      toast.success("Demande d'annulation de la vente envoyée.");
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        const errorMessage: string =
          error.response?.data?.errorMessage || "Une erreur est survenue";
        toast.error(errorMessage);
      }
      console.log("cancel sale request error", error);
    },
  });
};
