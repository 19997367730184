import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactQueryClientProvider from "@providers/ReactQueryClientProvider";
import CartProvider from "@providers/CartProvider";
import { AuthProvider } from "@providers/AuthContext";
import { Bounce, ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ReactQueryClientProvider>
      <CartProvider>
        <AuthProvider>
          <App />
          <ToastContainer
            position="top-right"
            toastStyle={{
              fontFamily: "Gotham",
              fontWeight: 500,
              gap: 10,
              color: "black",
              width: "fit-content",
              minWidth: 300,
              maxWidth: 400,
            }}
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}
          />
        </AuthProvider>
      </CartProvider>
    </ReactQueryClientProvider>
  </React.StrictMode>
);
