import clsx from "clsx";
import styles from "./Sidebar.module.css";
import { useState } from "react";
import Popover from "@components/Popover/Popover";
import PlusPopoverItem from "./PlusPopoverItem/PlusPopoverItem";
import ArchiveModal from "./ArchiveModal/ArchiveModal";
import StockModal from "./StockModal/StockModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "@providers/AuthContext";

export type SidebarTabType =
  | "retail"
  | "customers"
  | "receipt"
  | "help"
  | "plus"
  | "close";

function BarButton({
  iconSource,
  activeIconSource,
  label,
  selected,
  onClick,
}: {
  iconSource: string;
  activeIconSource: string;
  label: string;
  selected?: boolean;
  onClick: () => void;
}) {
  return (
    <div className={styles.barButton}>
      <button
        className={clsx(styles.iconContainer, {
          [styles.active]: selected,
        })}
        onClick={onClick}
      >
        <img
          src={selected ? activeIconSource : iconSource}
          className={styles.icon}
          alt="button"
        />
      </button>

      <label className={styles.label}>{label}</label>
    </div>
  );
}

export default function Sidebar({ onLogout }: { onLogout: () => void }) {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const { user } = useAuthContext();

  const urlBase = "/store/" + user.currentStoreId;

  const push = useNavigate();
  const location = useLocation();

  const [currentPlusModalOpen, setCurrentPlusModalOpen] = useState<
    "stock" | "archive" | null
  >(null);

  const plusPopoverContent = (
    <>
      <PlusPopoverItem
        icon={"/icons/layers-three-02.svg"}
        title={"Stocks"}
        label={"Consulter les stocks"}
        onClick={() => {
          setCurrentPlusModalOpen("stock");
          setPopoverOpen(false);
        }}
      />
      <PlusPopoverItem
        icon={"/icons/archive.svg"}
        title={"Archives"}
        label={"Générer des archives fiscales"}
        onClick={() => {
          setCurrentPlusModalOpen("archive");
          setPopoverOpen(false);
        }}
      />
      <div className={styles.divider}></div>
      <PlusPopoverItem
        icon={"/icons/loading-02.svg"}
        title={"Forcer une actualisation"}
        label={"Forcer le site à redémarrer"}
        // Hard reload
        onClick={() => {
          window.location.reload();
          setPopoverOpen(false);
        }}
      />
      <PlusPopoverItem
        icon={"/icons/lock-01.svg"}
        title={"Cloturer la caisse"}
        label={"Fermer la caisse"}
        onClick={() => {
          setPopoverOpen(false);
        }}
      />
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.barContainer}>
        <img
          src="/images/pxpr-logo.png"
          alt="PXPR Logo"
          width={52}
          height={52}
          className={styles.logo}
        />
        <div className={styles.buttonContainer}>
          <BarButton
            iconSource={"/icons/retail.svg"}
            label={"Caisse"}
            activeIconSource={"/icons/retail-active.svg"}
            selected={location.pathname === urlBase}
            onClick={() => push(urlBase + "?desk=" + user.currentDeskId)}
          />
          <BarButton
            iconSource={"/icons/users-01.svg"}
            activeIconSource={"/icons/users-01-active.svg"}
            label={"Clients"}
            selected={location.pathname === "/customers"}
            onClick={() => push("/customers")}
          />
          <BarButton
            iconSource={"/icons/receipt.svg"}
            activeIconSource={"/icons/receipt-active.svg"}
            label={"Ventes"}
            selected={location.pathname === "/sales"}
            onClick={() => push("/sales")}
          />
          <BarButton
            iconSource={"/icons/help-circle.svg"}
            activeIconSource={"/icons/help-circle-active.svg"}
            label={"Aide"}
            selected={location.pathname === "/help"}
            onClick={() => push("/help")}
          />

          {/* Not a tab bar button, should trigger a popup */}
          <Popover
            visible={isPopoverOpen}
            onVisibilityChange={setPopoverOpen}
            popoverContent={plusPopoverContent}
            title={"Plus"}
          >
            <BarButton
              iconSource={"/icons/dots-horizontal.svg"}
              activeIconSource={"/icons/dots-horizontal-active.svg"}
              label={"Plus"}
              selected={isPopoverOpen}
              onClick={() => setPopoverOpen((prev) => !prev)}
            />
          </Popover>

          {/* Popover modals */}
          <ArchiveModal
            isOpen={currentPlusModalOpen === "archive"}
            onClose={() => setCurrentPlusModalOpen(null)}
          />
          <StockModal
            isOpen={currentPlusModalOpen === "stock"}
            onClose={() => setCurrentPlusModalOpen(null)}
          />
        </div>

        <BarButton
          iconSource={"/icons/x.svg"}
          activeIconSource={"/icons/x-circle.svg"}
          label={"Clôturer la caisse"}
          selected={location.pathname === "/close"}
          onClick={() => push("/close")}
        />
      </div>

      <button className={styles.footer} onClick={onLogout}>
        <div className={styles.logoutButton}>
          <img
            src="/icons/log-out-02.svg"
            alt="logout"
            width={20}
            height={20}
            className={styles.icon}
          />

          {/* <label className={styles.label}>Vendeur</label> */}
        </div>
      </button>
    </div>
  );
}
