import api from "@api/api";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { UserReturnType } from "@pxp-caisse/shared/types/user";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const getMe = async (): Promise<UserReturnType> => {
  const { data }: AxiosResponse<UserReturnType> = await api.get("/auth/me");
  return data;
};

type QueryFnType = typeof getMe;

export const useGetMe = () => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["me"],
    queryFn: getMe,
  });
};
