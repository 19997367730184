import { CSSProperties } from "react";
import styles from "./Table.module.css";

import TableRow from "./TableRow";
import clsx from "clsx";

type TableBodyProps = {
  children:
    | React.ReactElement<typeof TableRow>[]
    | React.ReactElement<typeof TableRow>;
  style?: CSSProperties;
  alternateRowColor?: boolean;
  alternateCellColor?: boolean;
  className?: string;
};

const TableBody = ({
  children,
  style,
  alternateRowColor,
  alternateCellColor,
  className,
}: TableBodyProps) => {
  return (
    <tbody
      className={clsx(styles.body, className, {
        [styles.bodyAlternateRow]: alternateRowColor,
        [styles.bodyAlternateCell]: alternateCellColor,
      })}
      style={style}
    >
      {children}
    </tbody>
  );
};

export default TableBody;
