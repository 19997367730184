import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "@providers/AuthContext";

export default function ProtectedRoute() {
  const { isAuthenticated } = useAuthContext();

  if (isAuthenticated) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
}
