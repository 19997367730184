import styles from "./QuantitySelector.module.css";

type QuantitySelectorProps = {
  value: number;
  onDecrement: () => void;
  onIncrement: () => void;
};

export default function QuantitySelector({
  value,
  onDecrement,
  onIncrement,
}: QuantitySelectorProps) {
  return (
    <div className={styles.container}>
      <button
        onClick={() => {
          onDecrement();
        }}
        className={styles.button}
      >
        <img src="/icons/minus.svg" alt="Decrement" />
      </button>
      <div className={styles.content}>{value}</div>
      <button
        onClick={() => {
          onIncrement();
        }}
        className={styles.button}
      >
        <img src="/icons/plus.svg" alt="Increment" />
      </button>
    </div>
  );
}
