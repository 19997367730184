import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@components/Table";
import { ColumnType } from "@components/Table/TableHeader";
import moment from "moment";
import EmptyState from "@components/Empty/EmptyState";
import { RetailCustomer } from "@pxp-caisse/shared/types/customer";
import { useNavigate } from "react-router-dom";

const tableHeader: ColumnType[] = [
  {
    type: "text",
    key: "customer",
    label: "Client",
  },
  {
    type: "text",
    key: "mobile",
    label: "Téléphone",
  },
  {
    type: "text",
    key: "email",
    label: "Email",
  },
  {
    type: "text",
    key: "birthDate",
    label: "Date de naissance",
  },
  {
    type: "text",
    key: "postalCode",
    label: "Code postal",
  },
];

type CustomersTableProps = {
  customers: RetailCustomer[];
};

function CustomersTable({ customers }: CustomersTableProps) {
  const push = useNavigate();

  if (!customers?.length)
    return (
      <div style={{ marginTop: 150 }}>
        <EmptyState label="Aucune client trouvée, veuillez modifier votre recherche" />
      </div>
    );

  return (
    <Table>
      <TableHeader columns={tableHeader} />
      <TableBody>
        {customers.map((row) => {
          const fullName = (
            (row?.firstName ?? "") +
            " " +
            (row?.lastName ?? "")
          ).trim();
          return (
            <TableRow
              key={row.id}
              onClick={() => {
                push("/customers/" + row.id);
              }}
            >
              <TableCell>{!!fullName ? fullName : "Nom inconnu"}</TableCell>
              <TableCell>{row.mobile}</TableCell>
              <TableCell>{row?.email ?? "-"}</TableCell>
              <TableCell>
                {row.birthDate
                  ? moment(row.birthDate).format("DD/MM/YYYY")
                  : "-"}
              </TableCell>
              <TableCell>{row?.zipcode ?? "-"}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default CustomersTable;
