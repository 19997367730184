import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import RadioButton from "@components/Radio/RadioButton";
import { useAuthContext } from "@providers/AuthContext";
import { SaleHistory } from "@pxp-caisse/shared/types/sale";
import { useState } from "react";
import { isDev } from "@pxp-caisse/shared/utils/env";
import { useGetSalesGiftCards } from "@api/salesManagement/useGetSaleGiftCards";

type PrintTicketModalProps = {
  isOpen: boolean;
  onClose: () => void;
  sale: SaleHistory;
};

function PrintTicketModal({ isOpen, onClose, sale }: PrintTicketModalProps) {
  const [selected, setSelected] = useState<
    "NO_PRICE" | "DUPLICATA" | undefined
  >();
  const { data: giftCards } = useGetSalesGiftCards({
    invoiceKey: sale.invoiceKey,
  });
  const { user } = useAuthContext();

  const handlePrint = () => {
    if (selected === "NO_PRICE") {
      document.querySelector(".printReceipt").dispatchEvent(
        new CustomEvent("customEvent", {
          detail: {
            key: sale.invoiceKey,
            user: user.id,
            noPriceReceipt: true,
            isDev,
          },
        })
      );
      onClose();
      return;
    }
    if (selected === "DUPLICATA") {
      if (giftCards.length) {
        for (const giftCard of giftCards) {
          document.querySelector(".printGiftcardReceipt").dispatchEvent(
            new CustomEvent("customEvent", {
              detail: {
                code: giftCard.code,
              },
            })
          );
        }
      }
      document.querySelector(".printReceipt").dispatchEvent(
        new CustomEvent("customEvent", {
          detail: {
            key: sale.invoiceKey,
            duplicate: true,
            user: user.id,
            isDev,
          },
        })
      );
      onClose();
      return;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Imprimer">
      <div
        style={{
          display: "grid",
          gap: 10,
          borderBottom: "1px solid var(--grey-200)",
          paddingBottom: 20,
        }}
      >
        <RadioButton
          selected={selected === "NO_PRICE"}
          setSelected={setSelected}
          value="NO_PRICE"
          label="Ticket sans prix"
        />
        <RadioButton
          selected={selected === "DUPLICATA"}
          setSelected={setSelected}
          value="DUPLICATA"
          label="Duplicata"
        />
      </div>
      <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
        <Button variant="light" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="dark" onClick={handlePrint}>
          Imprimer
        </Button>
      </div>
    </Modal>
  );
}

export default PrintTicketModal;
