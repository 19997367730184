import { CSSProperties } from "react";
import styles from "./Table.module.css";
import clsx from "clsx";

export type ColumnType =
  | {
      type: "text";
      key: string;
      label: string;
    }
  | {
      type: "action";
      key: string;
      ActionComponent: React.ReactElement;
    };

type TableHeaderProps = {
  style?: CSSProperties;
  headerCellStyle?: CSSProperties;
  columns: ColumnType[];
  sticky?: boolean;
};

const TableHeader = ({
  style,
  columns,
  headerCellStyle,
  sticky = false,
}: TableHeaderProps) => {
  return (
    <thead
      className={clsx(styles.header, { [styles.sticky]: sticky })}
      style={style}
    >
      <tr className={styles.headerTr}>
        {columns.map((column) => {
          if (column.type === "text") {
            return (
              <th key={column.key} style={headerCellStyle}>
                {column.label}
              </th>
            );
          }
          if (column.type === "action") {
            return (
              <th key={column.key} style={headerCellStyle}>
                {column.ActionComponent}
              </th>
            );
          }

          return null;
        })}
      </tr>
    </thead>
  );
};

export default TableHeader;
