import { CSSProperties } from "react";
import styles from "./Select.module.css";
import clsx from "clsx";

export const blankValue = 0;

export type SelectValue = string | number;

export type SelectedOption = {
  name: string;
  value: SelectValue;
};

export default function Select({
  options,
  style,
  label,
  value,
  onChange,
  variant = "normal",
  placeholder,
  selectStyle,
  smallerSides,
}: {
  options: SelectedOption[];
  style?: CSSProperties;
  onChange: (value: string) => void;
  label?: string;
  value: SelectValue;
  variant?: "normal" | "small";
  placeholder?: string;
  selectStyle?: CSSProperties;
  smallerSides?: boolean;
}) {
  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      <div
        className={clsx(styles.selectContainer, {
          [styles.small]: variant === "small",
          [styles.normal]: variant === "normal",
        })}
        style={style}
      >
        <select
          className={clsx(styles.select, {
            [styles.option]: value !== "",
            [styles.placeholder]: value === "",
            [styles.smallerSides]: smallerSides,
          })}
          value={value} // Use value here to bind the selected option
          onChange={(e) => {
            onChange(e.target.value);

            // Removing focus on select
            e.currentTarget.blur();
          }}
          style={selectStyle}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          {options.map((option, index) => (
            <option value={option.value} key={option.value ?? index}>
              {option.name}
            </option>
          ))}
        </select>

        <img
          src="/icons/arrow-down.svg"
          alt="Arrow down"
          width={12}
          height={6}
          aria-hidden={true}
          className={clsx(styles.arrowDownIcon, {
            [styles.reduced]: smallerSides,
          })}
        />
      </div>
    </div>
  );
}
