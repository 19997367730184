import { useGetStocks } from "@api/products/getStocks";
import Modal from "@components/Modal/Modal";
import ReferenceField, {
  ReferenceTypeWithOptionalSize,
} from "@components/ReferenceField/ReferenceField";
import { useGlobalContext } from "@providers/GlobalProvider";
import "moment/locale/fr";
import { useMemo, useState } from "react";
import styles from "./StockModal.module.css";
import StockTable from "./StockTable";

type StockModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
export default function StockModal({ isOpen, onClose }: StockModalProps) {
  const { state } = useGlobalContext();

  const [referenceValue, setReferenceValue] = useState<
    ReferenceTypeWithOptionalSize<true>
  >({
    reference: "",
    color: "",
  });
  const [triggerFetch, setTriggerFetch] = useState(false);

  // Does not fetch prices
  const { data } = useGetStocks(referenceValue, triggerFetch);

  const handleReferenceChange = (
    reference: ReferenceTypeWithOptionalSize<true>,
    validReference: boolean
  ) => {
    setReferenceValue(reference);

    if (validReference) {
      setTriggerFetch(true);
    } else {
      setTriggerFetch(false);
    }
  };

  const dataWithPrices = useMemo(() => {
    const getListPriceForProduct = (ean: string) => {
      return state.products.find((p) => p.ean === ean).list_price;
    };

    if (!data) {
      return [];
    }

    return data.map((product) => {
      return {
        ...product,
        list_price: getListPriceForProduct(product.ean),
      };
    });
  }, [data, state.products]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setTriggerFetch(false);
        setReferenceValue({
          reference: "",
          color: "",
        });
      }}
      title="Produits en stock"
    >
      <div className={styles.container}>
        <p className={styles.label}>
          Recherchez une référence pour consulter les stocks
        </p>

        <ReferenceField
          handleChange={handleReferenceChange}
          referenceValue={referenceValue}
          hideSize
        />
      </div>

      <div className={styles.tableContainer}>
        <StockTable data={dataWithPrices} />
      </div>
    </Modal>
  );
}
