import { SaleHistory, SaleHistoryProduct } from "@pxp-caisse/shared/types/sale";
import styles from "../page.module.css";
import { parseFloat2 } from "@pxp-caisse/shared/utils/conversion";
import Separator from "@components/Separator/Separator";
import Button from "@components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "@providers/AuthContext";
import { useState } from "react";
import PrintTicketModal from "./PrintTicketModal";
import CancelSaleModal from "./CancelSaleModal";
import ChangePaymentMethodModal from "./ChangePaymentMethodModal";
import SaleCustomerInfoModal from "./SaleCustomerInfoModal";

type SaleModalProps = {
  sale: SaleHistory;
};

function SaleModal({ sale }: SaleModalProps) {
  const { user } = useAuthContext();

  const [isTicketModalOpen, setIsTicketModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isCustomerInfoModalOpen, setIsCustomerInfoModalOpen] = useState(false);

  return (
    <>
      <div className={styles.saleModal}>
        <div className={styles.saleModalContent}>
          <div className={styles.saleModalHeader}>
            <h2>Détails des achats</h2>
            {sale.customerId && (
              <button
                style={{
                  all: "unset",
                  display: "grid",
                  gap: 10,
                  paddingTop: 20,
                  cursor: "pointer",
                }}
                onClick={() => setIsCustomerInfoModalOpen(true)}
              >
                <p style={{ color: "#475468", fontSize: 15 }}>Client</p>
                <p>
                  {sale.customerName && sale.customerName !== " "
                    ? sale.customerName
                    : "Nom pas fourni"}
                </p>
              </button>
            )}
          </div>

          {sale.products.map((product: SaleHistoryProduct, index) => (
            <div key={index} className={styles.saleProductCard}>
              <div className={styles.saleModalProduct}>
                <div style={{ display: "grid", flex: 1, gap: 20 }}>
                  <h2>
                    {product.quantity} x {product.productDescription}
                  </h2>
                  <div
                    style={{
                      paddingTop: 10,
                      display: "grid",
                      gap: 10,
                      color: "#475468",
                    }}
                  >
                    <p>{product.productReference}</p>
                    {parseFloat2(String(product?.discount)) !== 0 && (
                      <p>
                        {!!parseFloat2(String(product.discountValue))
                          ? parseFloat2(
                              (parseFloat2(String(product.discountValue)) /
                                (parseFloat2(String(product.productUnitPrice)) *
                                  product.quantity)) *
                                100
                            ).toFixed(2)
                          : parseFloat2(String(product.discount)) || "0"}
                        % de remise
                      </p>
                    )}
                  </div>
                </div>
                <h2>
                  {product.paidAmount} {user.currency}
                </h2>
              </div>
            </div>
          ))}
        </div>
        <div style={{ padding: "0px 20px" }}>
          <Separator direction="horizontal" />
        </div>
        <div className={styles.saleModalFooter}>
          <Button
            style={{ flex: 2.5 }}
            onClick={() => setIsPaymentModalOpen(true)}
          >
            Changer le moyen de paiement
          </Button>
          <Button
            style={{ flex: 1 }}
            variant="destructive"
            onClick={() => setIsCancelModalOpen(true)}
          >
            Anuller
          </Button>
          <Button
            style={{ flex: 1 }}
            variant="tertiary"
            onClick={() => setIsTicketModalOpen(true)}
          >
            <span style={{ display: "flex", gap: 8, justifyContent: "center" }}>
              <FontAwesomeIcon icon={faPrint} />
              Imprimer
            </span>
          </Button>
        </div>
      </div>
      <PrintTicketModal
        isOpen={isTicketModalOpen}
        onClose={() => setIsTicketModalOpen(false)}
        sale={sale}
      />
      <CancelSaleModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        sale={sale}
      />
      <ChangePaymentMethodModal
        isOpen={isPaymentModalOpen}
        onClose={() => setIsPaymentModalOpen(false)}
        sale={sale}
      />
      {sale.customerId && (
        <SaleCustomerInfoModal
          isOpen={isCustomerInfoModalOpen}
          onClose={() => setIsCustomerInfoModalOpen(false)}
          sale={sale}
        />
      )}
    </>
  );
}

export default SaleModal;
