import { AxiosResponse } from "axios";
import api from "../api";
import { useMutation } from "@tanstack/react-query";

interface LoginUserParams {
  storeId: string;
  deskId: number;
  userCode: string;
}

interface LoginResponse {
  accessToken: string;
}

const loginUser = async ({
  storeId,
  userCode,
  deskId,
}: LoginUserParams): Promise<LoginResponse> => {
  const { data }: AxiosResponse<LoginResponse> = await api.post(`auth/login`, {
    userCode,
    deskId,
    storeId,
  });
  return data;
};

const useLoginUser = () => {
  return useMutation({
    mutationFn: (data: LoginUserParams) => loginUser(data),
  });
};

export default useLoginUser;
