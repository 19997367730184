import React, { useState, useEffect, useRef } from "react";
import styles from "./Popover.module.css";

interface PopoverProps {
  children: React.ReactNode;
  visible: boolean;
  onVisibilityChange?: (visible: boolean) => void;
  popoverContent: React.ReactNode;
  title: string;
}

const Popover: React.FC<PopoverProps> = ({
  children,
  visible,
  onVisibilityChange,
  popoverContent,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(visible);
  const popoverRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  useEffect(() => {
    // Close popover if clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        if (onVisibilityChange) {
          onVisibilityChange(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onVisibilityChange]);

  const togglePopover = () => {
    setIsOpen((prev) => !prev);
    if (onVisibilityChange) {
      onVisibilityChange(!isOpen);
    }
  };

  return (
    <div className={styles.popoverContainer} ref={triggerRef}>
      <div onClick={togglePopover}>{children}</div>
      {isOpen && (
        <div className={styles.popoverContent} ref={popoverRef}>
          <div className={styles.titleContainer}>
            <p className={styles.title}>{title}</p>
          </div>

          <div className={styles.contentContainer}>{popoverContent}</div>
        </div>
      )}
    </div>
  );
};

export default Popover;
