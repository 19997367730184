import { FormEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLoginUser from "@api/auth/login.mutation";
import Button from "@components/Button/Button";
import HorizontalTab from "@components/HorizontalTab/HorizontalTab";
import PasswordTextInput from "@components/PasswordTextInput/PasswordTextInput";
import Select, { SelectValue } from "@components/Select/Select";
import { useAuthStore } from "@hooks/useAuthStore.hook";
import styles from "./page.module.css";
import { Store } from "@pxp-caisse/shared/types/store";
import { useAuthContext } from "@providers/AuthContext";

export default function ConnectionForm({ stores }: { stores: Store[] }) {
  const { mutate: loginUser } = useLoginUser();
  const navigate = useNavigate();
  const { setToken } = useAuthStore((state) => state);

  const storesOptions = useMemo(
    () =>
      stores.map((store) => {
        return {
          value: store.id,
          name: store.name,
        };
      }),
    [stores]
  );

  const [selectedStore, setSelectedStore] = useState<SelectValue>(
    storesOptions?.[0]?.value ?? 1
  );
  const [selectedDesk, setSelectedDesk] = useState<SelectValue>(1);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(false);
  const { fetchUser } = useAuthContext();

  const desksOptions = useMemo(() => {
    const store = stores.find(
      (s) => s.id.toString() === selectedStore.toString()
    );

    const desks =
      store?.desks.map((desk) => {
        return {
          id: desk.id,
          value: desk.numero,
          name: `Caisse ${desk.numero}`,
        };
      }) ?? [];

    setSelectedDesk(1);

    return desks;
  }, [selectedStore, stores]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const selectedDeskId = desksOptions.find(
      (desk) => desk.value === selectedDesk
    ).id;

    setDisabled(true);
    loginUser(
      {
        storeId: selectedStore.toString(),
        userCode: password,
        deskId: selectedDeskId,
      },
      {
        onSuccess: (data) => {
          setErrorMessage(null);
          setToken(data.accessToken);
          fetchUser();
          navigate(`/store/${selectedStore}?desk=${selectedDesk}`);
        },
        onError: () => {
          setErrorMessage("Code invalide");
        },
        onSettled: () => setDisabled(false),
      }
    );
  };

  return (
    <form action="" className={styles.form} onSubmit={handleSubmit}>
      <h1 className={styles.title}>Identifiez-vous</h1>

      <div className={styles.inputContainer}>
        <Select
          options={storesOptions}
          label="Sélectionnez votre magasin"
          value={selectedStore}
          onChange={(value) => setSelectedStore(value)}
        />

        <HorizontalTab
          options={desksOptions}
          selected={selectedDesk}
          onChange={(value) => setSelectedDesk(value)}
          label={"Sélectionnez votre caisse"}
        />

        <PasswordTextInput
          value={password}
          onChange={(value) => setPassword(value)}
          label={"Identifiant"}
        />

        {errorMessage && <p className={styles.errorLabel}>{errorMessage}</p>}
      </div>

      <Button label={"Se connecter"} disabled={disabled} type={"submit"} />
    </form>
  );
}
