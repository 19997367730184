import DatePicker from "@components/Date/DatePicker";
import styles from "./ArchiveModal.module.css";
import Modal from "@components/Modal/Modal";
import { useEffect, useState } from "react";
import "moment/locale/fr";
import Button from "@components/Button/Button";
import { useAuthContext } from "@providers/AuthContext";
import { useGetArchiveFile } from "@api/archives/get";
import Spinner from "@components/Loader/Spinner";
import moment from "moment";

type ArchiveModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ArchiveModal({ isOpen, onClose }: ArchiveModalProps) {
  const [dateStart, setDateStart] = useState<Date | null>(
    moment().subtract(7, "days").toDate()
  );
  const [dateEnd, setDateEnd] = useState<Date | null>(new Date());
  const [triggerFetch, setTriggerFetch] = useState(false);

  const { user } = useAuthContext();

  const params = {
    begin: dateStart?.toISOString().split("T")[0],
    end: dateEnd?.toISOString().split("T")[0],
  };

  const { data, isLoading } = useGetArchiveFile(triggerFetch, params);

  const handleConfirm = () => {
    if (!dateStart || !dateEnd) {
      return;
    }

    // Trigger fetch
    setTriggerFetch(true);
  };

  useEffect(() => {
    if (data) {
      const url = window.URL.createObjectURL(data);

      // Open a new tab with the archive
      const link = document.createElement("a");
      link.href = url;
      link.download = `Archive_${moment(dateStart).format(
        "DD-MM-YYYY"
      )}_${moment(dateEnd).format("DD-MM-YYYY")}_${user.currentStoreName}.zip`;
      link.click();

      // Revoke the object URL after the download starts
      window.URL.revokeObjectURL(url);

      // Reset fetch state after download
      setTriggerFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Générer des archives fiscales"
    >
      <div className={styles.container}>
        <p className={styles.label}>
          Générer les archives fiscales pour la période du
        </p>

        <DatePicker
          type="dateRange"
          setSelectedStartDate={setDateStart}
          setSelectedEndDate={setDateEnd}
        />
      </div>

      {isLoading && (
        <div style={{ paddingBottom: "24px" }}>
          <Spinner size="medium" />
        </div>
      )}

      <div className={styles.buttonContainer}>
        <Button variant="light" onClick={onClose}>
          Annuler
        </Button>

        <Button onClick={handleConfirm} disabled={isLoading}>
          Confirmer
        </Button>
      </div>
    </Modal>
  );
}
