export const salesHistoryKeys = {
  all: ["salesHistory"] as const,
  list: (data: SalesListParams) =>
    [...salesHistoryKeys.all, "list", data] as const,
  turnover: (data: SalesListParams) =>
    [...salesHistoryKeys.all, "turnover", data] as const,
  getSale: (id: number) => [...salesHistoryKeys.all, "getSale", id] as const,
};

export type SalesListParams = {
  begin: string;
  end: string;
  page?: number;
  customerId?: number;
};
