import { Controller } from "react-hook-form";
import TextField, { TextFieldProps } from "./TextField";

export type TextFieldRhfProps = Pick<
  TextFieldProps,
  | "style"
  | "leftIconSrc"
  | "error"
  | "placeholder"
  | "type"
  | "multiline"
  | "label"
  | "showError"
> & {
  name: string;
  control: any;
  defaultValue?: any;
} & {
  matchId?: boolean;
};

export function TextFieldRhf({
  name,
  control,
  defaultValue,
  ...inputProps
}: TextFieldRhfProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value = "" }, fieldState: { error } }) => {
        return (
          <TextField
            {...inputProps}
            value={value}
            error={error?.message}
            onChange={(v) => {
              onChange(v);
            }}
          />
        );
      }}
    />
  );
}
