import api from "@api/api";
import { archiveKeys } from "./queryKeys";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";

export type ArchiveParams = {
  begin: string;
  end: string;
};

const getArchiveFile = async (params: ArchiveParams): Promise<Blob> => {
  const { data }: AxiosResponse<Blob> = await api.get(
    "/archives/archives.zip",
    {
      params,
      responseType: "blob",
    }
  );
  return data;
};

type QueryFnType = typeof getArchiveFile;

export const useGetArchiveFile = (enabled: boolean, params: ArchiveParams) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: archiveKeys.download(params),
    queryFn: () => getArchiveFile(params),
    enabled,
  });
};
