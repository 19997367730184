import { useState, useEffect } from "react";
import styles from "./page.module.css";

const LoadingPage = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return oldProgress + 5;
      });
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      <img src="/images/splash.png" alt="Loading" className={styles.logo} />
      <div className={styles.progressBar}>
        <div
          className={styles.progressFill}
          style={{ width: `${progress}%` }}
        />
      </div>
      <div className={styles.progressText}>Loading...</div>
    </div>
  );
};

export default LoadingPage;
