"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GIFT_CERTIFICATES_TYPES_LABEL_OBJ = exports.GIFT_CERTIFICATES_TYPES_OPTIONS = exports.GIFT_CERTIFICATES_TYPES_OBJ = exports.GIFT_CERTIFICATES_TYPES = void 0;
const types_1 = require("../utils/types");
exports.GIFT_CERTIFICATES_TYPES = [
    "CAD",
    "TIR",
    "KAD",
    "ED",
    "HDF",
    "JC",
];
exports.GIFT_CERTIFICATES_TYPES_OBJ = (0, types_1.generateTypeObjectFromEnum)(exports.GIFT_CERTIFICATES_TYPES);
exports.GIFT_CERTIFICATES_TYPES_OPTIONS = {
    CAD: "Cadhoc",
    TIR: "Tirgroupé",
    KAD: "Kadeos",
    ED: "Edenred",
    HDF: "HDF",
    JC: "Jeu concours",
};
exports.GIFT_CERTIFICATES_TYPES_LABEL_OBJ = (0, types_1.generateOptionsFromLabelObj)(exports.GIFT_CERTIFICATES_TYPES_OPTIONS);
