import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "@components/Table";
import { ColumnType } from "@components/Table/TableHeader";
import { useAuthContext } from "@providers/AuthContext";
import { Product } from "@pxp-caisse/shared/types/products";

const tableHeader: ColumnType[] = [
  {
    type: "text",
    key: "label",
    label: "Taille",
  },
  {
    type: "text",
    key: "quantity_available",
    label: "Stocks",
  },
  {
    type: "text",
    key: "list_price",
    label: "Prix (en €)",
  },
];

type StockTableProps = {
  data: Product[];
};

function StockTable({ data }: StockTableProps) {
  const { user } = useAuthContext();

  const tableCellStyle = {
    padding: "16px",
  };

  return (
    <>
      <Table
        style={{
          border: "0px",
          borderRadius: "0px",
        }}
      >
        <TableHeader
          columns={tableHeader}
          style={{
            backgroundColor: "var(--grey-50)",
          }}
          headerCellStyle={{
            padding: "12px 24px",
            borderBottom: "1px solid var(--grey-200)",
            color: "var(--grey-900)",
            fontFamily: "Gotham",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        />
        <TableBody>
          {data.map((product) => {
            return (
              <TableRow key={product.ean} disableHover>
                <TableCell style={tableCellStyle}>{product.label}</TableCell>
                <TableCell style={tableCellStyle}>
                  {product.quantity_available}
                </TableCell>
                <TableCell style={tableCellStyle}>
                  <p
                    style={{
                      textAlign: "end",
                      width: "100%",
                    }}
                  >
                    {product.list_price} {user.currency}
                  </p>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}

export default StockTable;
