import api from "@api/api";
import { productsKeys } from "./queryKeys";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { Product } from "@pxp-caisse/shared/types/products";

const getProducts = async () => {
  const { data }: AxiosResponse<{ status: number; products: Product[] }> =
    await api.get("/products");
  return data.products;
};

type QueryFnType = typeof getProducts;

export const useGetProducts = () => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: productsKeys.getProducts(),
    queryFn: () => getProducts(),
  });
};
