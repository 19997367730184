import { useAuthContext } from "@providers/AuthContext";
import { useEffect, useState } from "react";
import Select, { SelectValue } from "../Select/Select";
import styles from "./TopBar.module.css";
import { useLocation } from "react-router-dom";

const twoPadStart = (value: number) =>
  value?.toString()?.padStart(2, "0") ?? "";

const formatTime = (date: Date) => {
  const hours = twoPadStart(date.getHours());
  const minutes = twoPadStart(date.getMinutes());
  const seconds = twoPadStart(date.getSeconds());

  return `${hours}:${minutes}:${seconds}`;
};

export default function TopBar({
  currentCart,
  onCartChange,
}: {
  currentCart: SelectValue;
  onCartChange: (cart: SelectValue) => void;
}) {
  const { user } = useAuthContext();
  const storeName = user.currentStoreName;
  const location = useLocation();

  const [time, setTime] = useState(formatTime(new Date()));
  const cartOptions = [
    {
      name: "Panier 1",
      value: 1,
    },
    {
      name: "Panier 2",
      value: 2,
    },
  ];

  useEffect(() => {
    setInterval(() => setTime(formatTime(new Date())), 1000);
  }, []);

  return (
    <div className={styles.topBar}>
      <div style={{ width: "90px" }} />

      <div className={styles.titleContainer}>
        <h2 className={styles.title}>PXP {storeName}</h2>
        {location.pathname.startsWith("/store") && (
          <Select
            options={cartOptions}
            value={currentCart}
            onChange={(value) => onCartChange(value)}
            variant="small"
          />
        )}
      </div>

      <div className={styles.timeLabel}>{time}</div>
    </div>
  );
}
