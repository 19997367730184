import styles from "./Empty.module.css";

type EmptyStateProps = {
  label: string;
  width?: string;
  icon?: string;
};

function EmptyState({
  label,
  width = "20%",
  icon = "/icons/empty-cart.svg",
}: EmptyStateProps) {
  return (
    <div className={styles.container}>
      <img src={icon} alt="empty-state" width={width} />
      <h3>{label}</h3>
    </div>
  );
}

export default EmptyState;
