import api from "@api/api";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { UpdatePaymentMethodParams } from "./queryKeys";
import { queryClient } from "@providers/ReactQueryClientProvider";
import { salesHistoryKeys } from "@api/salesHistory/queryKeys";

export const updatePaymentMethod = async (
  params: UpdatePaymentMethodParams
) => {
  await api.post("/sales/management/update-payment-method", params);
};

export const useUpdatePaymentMethod = () => {
  return useMutation({
    mutationFn: updatePaymentMethod,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: salesHistoryKeys.all,
      });
      toast.success("Le paiement a bien été mis à jour.");
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        const errorMessage: string =
          error.response?.data?.errorMessage || "Une erreur est survenue";
        toast.error(errorMessage);
      }
      console.log("cancel sale request error", error);
    },
  });
};
