import Button from "@components/Button/Button";
import CurrencyInput from "@components/CurrencyInput/CurrencyInput";
import Modal from "@components/Modal/Modal";
import { useAuthContext } from "@providers/AuthContext";
import { SaleHistory } from "@pxp-caisse/shared/types/sale";
import { parseFloat2 } from "@pxp-caisse/shared/utils/conversion";
import { useMemo, useState } from "react";
import styles from "../page.module.css";
import { useUpdatePaymentMethod } from "@api/salesManagement/useUpdatePaymentMethod";
import Spinner from "@components/Loader/Spinner";

// Test

type ChangePaymentMethodModalProps = {
  sale: SaleHistory;
  onClose: () => void;
  isOpen: boolean;
};

function ChangePaymentMethodModal({
  sale,
  onClose,
  isOpen,
}: ChangePaymentMethodModalProps) {
  const { user } = useAuthContext();
  const [cash, setCash] = useState<number>(0);
  const [card, setCard] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const totalAmount = useMemo(() => {
    return parseFloat2(
      sale.paidAmount -
        (sale.giftCertificatesAmount || 0) -
        (sale.vouchersAmount || 0) -
        (sale.giftcardsAmount || 0)
    );
  }, [
    sale.paidAmount,
    sale.giftCertificatesAmount,
    sale.vouchersAmount,
    sale.giftcardsAmount,
  ]);

  const updatePaymentMethodMutation = useUpdatePaymentMethod();

  const onSubmit = () => {
    setIsSubmitting(true);
    updatePaymentMethodMutation.mutate(
      {
        invoiceKey: sale.invoiceKey,
        cashAmount: cash,
        cardAmount: card,
      },
      {
        onSuccess: () => {
          onClose();
        },
        onSettled: () => {
          setIsSubmitting(false);
        },
      }
    );
  };

  const onCashChange = (value: number) => {
    if (Number.isNaN(value)) {
      setCash(0);
      setCard(totalAmount);
      return;
    }

    const newCash = parseFloat2(Math.min(totalAmount, value));
    const newCard = parseFloat2(totalAmount - newCash);
    setCash(newCash);
    setCard(newCard);
  };

  const onCardChange = (value: number) => {
    if (Number.isNaN(value)) {
      setCash(totalAmount);
      setCard(0);
      return;
    }

    const newCard = parseFloat2(Math.min(totalAmount, value));
    const newCash = parseFloat2(totalAmount - newCard);
    setCard(newCard);
    setCash(newCash);
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Changer le moyen de payement"
      width="35vw"
    >
      <div className={styles.changePaymentMethodContainer}>
        <div className={styles.changePaymentInput}>
          <label>Montant total Cash & CB</label>
          <p>
            <b>
              {totalAmount} {user.currency}
            </b>
          </p>
        </div>
        <div className={styles.changePaymentInput}>
          <label>Montant Cash</label>
          <CurrencyInput
            value={cash}
            onChange={onCashChange}
            max={totalAmount}
          />
        </div>
        <div className={styles.changePaymentInput}>
          <label>Montant CB</label>
          <CurrencyInput
            value={card}
            onChange={onCardChange}
            max={totalAmount}
          />
        </div>
      </div>
      <div className={styles.modalFooters}>
        {!isSubmitting ? (
          <>
            <Button variant="light" onClick={onClose}>
              Annuler
            </Button>
            <Button variant="dark" onClick={onSubmit}>
              Valider
            </Button>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </Modal>
  );
}

export default ChangePaymentMethodModal;
