import { useGlobalContext } from "@providers/GlobalProvider";
import Select, { SelectedOption } from "../Select/Select";
import TextField from "../TextField/TextField";
import styles from "./ReferenceField.module.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { Product } from "@pxp-caisse/shared/types/products";

export type ReferenceType = {
  reference: string;
  color: string;
  size: string;
};

export type ReferenceTypeWithOptionalSize<T extends boolean = false> =
  T extends true ? Omit<ReferenceType, "size"> : ReferenceType;

export default function ReferenceField({
  handleChange,
  referenceValue,
  hideSize = false,
}: {
  hideSize?: boolean;
  handleChange: (
    value: ReferenceTypeWithOptionalSize<typeof hideSize>,

    // True when selecting an autocomplete item or changing color/size
    validReference?: boolean
  ) => void;
  referenceValue: ReferenceTypeWithOptionalSize<typeof hideSize>;
}) {
  const { state } = useGlobalContext();
  const [isAutoCompleteVisible, setIsAutoCompleteVisible] = useState(false);
  const autoCompleteRef = useRef(null);
  const referenceInputRef = useRef(null);

  const [colorOptions, setColorOptions] = useState<SelectedOption[]>([]);
  const [sizeOptions, setSizeOptions] = useState<SelectedOption[]>([]);

  const products = useMemo(() => {
    if (!state.products) {
      return [];
    }

    const productsWithoutSurpriseGifts = state.products.filter(
      ({ reference }) => {
        return (
          !reference.startsWith("GD-") &&
          reference !== "Carte-Cadeau-rechargeable" &&
          reference !== "Recharge carte cadeau"
        );
      }
    );

    // Remove redundant size
    if (hideSize) {
      const groupedProducts: {
        [key: string]: Product;
      } = productsWithoutSurpriseGifts.reduce((acc, product) => {
        const key = `${product.reference}-${product.color_reference}`;

        if (!acc[key]) {
          acc[key] = product;
        }

        return acc;
      }, {});

      return Object.values(groupedProducts);
    }

    return productsWithoutSurpriseGifts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.products]);

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (
        referenceInputRef.current &&
        !referenceInputRef.current.contains(event.target) &&
        autoCompleteRef.current &&
        !autoCompleteRef.current.contains(event.target)
      ) {
        setIsAutoCompleteVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const autoCompleteProductList = useMemo(() => {
    if (referenceValue.reference === "") {
      return [];
    }

    return products
      .filter(({ reference }) =>
        reference
          .toLowerCase()
          .startsWith(referenceValue.reference.toLowerCase())
      )
      .slice(0, 6);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceValue.reference]);

  const handleChangeReference = (value: string) => {
    setIsAutoCompleteVisible(true);
    handleChange({ ...referenceValue, reference: value });
  };

  const handleChangeColor = (value: string) => {
    const sizeOptions = findSizesForReferenceAndColor(
      referenceValue.reference,
      referenceValue.color
    );

    let newSize = undefined;
    if (!hideSize) {
      const typeReferenceValue = referenceValue as ReferenceType;
      newSize = sizeOptions
        .map((s) => s.value)
        .includes(typeReferenceValue?.size ?? "")
        ? typeReferenceValue.size
        : sizeOptions[0].value;

      setSizeOptions(sizeOptions);
    }

    handleChange(
      {
        ...referenceValue,
        color: value,
        size: newSize,
      },
      true
    );
  };

  const handleBlur = () => {
    const found = products.find(
      (p) => p.reference === referenceValue.reference
    );

    if (!found) {
      handleChange({
        ...referenceValue,
        color: "",
        size: "",
      });
    }
  };

  const handleChangeSize = (value: string) => {
    handleChange({ ...referenceValue, size: value } as ReferenceType, true);
  };

  const handleSelectAutoCompleteItem = (product: Product) => {
    setColorOptions(findColorsForReference(product.reference));
    setSizeOptions(
      findSizesForReferenceAndColor(product.reference, product.color_reference)
    );

    handleChange(
      {
        reference: product.reference,
        color: product.color_reference,
        size: product.label,
      },
      true
    );

    setIsAutoCompleteVisible(false);
  };

  const findColorsForReference = (reference: string): SelectedOption[] => {
    const colorSet: Set<string> = new Set();
    products.forEach((product) => {
      if (product.reference === reference) {
        colorSet.add(product.color_reference);
      }
    });

    const colors = [...colorSet];
    colors.sort();
    return colors.map((color) => {
      return { name: color, value: color };
    });
  };

  const findSizesForReferenceAndColor = (reference: string, color: string) => {
    const sizes = products
      .filter(
        (product) =>
          product.reference === reference && product.color_reference === color
      )
      .map((p) => {
        return { name: p.label, value: p.label };
      });

    return sizes;
  };

  return (
    <div className={styles.container}>
      <TextField
        ref={referenceInputRef}
        value={referenceValue.reference}
        onChange={handleChangeReference}
        onBlur={handleBlur}
        style={{
          borderRadius: "40px 0px 0px 40px",
          flex: 1,
          minWidth: "120px",
          flexGrow: 1,
          zIndex: 3,
        }}
        placeholder="Référence"
      />

      {isAutoCompleteVisible && autoCompleteProductList.length !== 0 && (
        <div className={styles.autoCompleteContainer} ref={autoCompleteRef}>
          {autoCompleteProductList.map((product) => {
            return (
              <div
                className={styles.autoCompleteItem}
                key={product.ean}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectAutoCompleteItem(product);
                }}
              >
                {product.reference} - {product.color_reference}
                {hideSize ? "" : " - " + product.label}
              </div>
            );
          })}
        </div>
      )}

      <Select
        options={colorOptions}
        onChange={handleChangeColor}
        value={referenceValue.color}
        placeholder="Couleur"
        selectStyle={{
          flex: 1,
          borderRadius: hideSize ? "0px 40px 40px 0px" : 0,
          borderLeft: "1px solid transparent",
          fontFamily: "Gotham",
          fontWeight: 400,
          minWidth: hideSize ? "180px" : "100px",
          fontSize: "14px",
          zIndex: 3,
        }}
        smallerSides
      />

      {!hideSize && (
        <Select
          options={sizeOptions}
          onChange={handleChangeSize}
          value={referenceValue["size"]}
          placeholder="Taille"
          selectStyle={{
            flex: 1,
            borderRadius: "0px 40px 40px 0px",
            borderLeft: "1px solid transparent",
            fontFamily: "Gotham",
            fontWeight: 400,
            minWidth: "100px",
            fontSize: "14px",
            zIndex: 3,
          }}
          smallerSides
        />
      )}
    </div>
  );
}
