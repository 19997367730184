import React, { useState } from "react";
import styles from "./FileInput.module.css";

const FileInput = ({ onFileSelect }) => {
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file: File = event.target.files[0];
    if (file) {
      setFileName(file.name);
      if (onFileSelect) {
        onFileSelect(file);
      }
    }
  };

  return (
    <div className={styles.fileInputContainer}>
      <input
        type="file"
        id="fileInput"
        className={styles.hiddenInput}
        onChange={handleFileChange}
      />
      <label htmlFor="fileInput" className={styles.customButton}>
        Choisisser un fichier
      </label>
      {fileName && <span className={styles.fileName}>{fileName}</span>}
    </div>
  );
};

export default FileInput;
