import api from "@api/api";
import { GiftCertificatesType } from "@pxp-caisse/shared/types/giftCertificates";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { CloseDeskErrorResponse } from "@pxp-caisse/shared/types/accounting";

export type CloseDeskParams = {
  cashFloat: number;
  cashFloatDetail: {
    amount: number;
    quantity: number;
  }[];
  bankDetail: {
    amount: number;
    quantity: number;
  }[];
  bankAmount: number;
  cashDisbursement: number;
  cashDisbursementJustification?: string;
  ccTurnover: number;
  giftCertificatesTurnover: number;
  giftCertificatesDetail: {
    amount: number;
    type: GiftCertificatesType;
    quantity: number;
  }[];
  now: Date;
  errorJustification?: string;
  force: boolean;
};

const closeDesk = async (params: CloseDeskParams) => {
  const { data } = await api.post<CloseDeskErrorResponse>(
    "/fund/close",
    params
  );

  return data;
};

export const useCloseDesk = () => {
  return useMutation({
    mutationFn: closeDesk,
    onError: (error) => {
      if (isAxiosError(error)) {
        const errorMessage: string =
          error.response?.data?.errorMessage || "Une erreur est survenue";
        toast.error(errorMessage);
      }
      console.log("close desk error", error);
    },
  });
};
