import clsx from "clsx";
import styles from "./Pagination.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  onChange: (page: number) => void;
};

const Pagination = ({ currentPage, totalPages, onChange }: PaginationProps) => {
  const getPageNumbersToShow = (): (number | string)[] => {
    const pages: (number | string)[] = [];

    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) pages.push(i);
    } else {
      pages.push(1);
      if (currentPage > 2) pages.push("...");
      const start = Math.max(2, currentPage - 1);
      const end = Math.min(totalPages - 1, currentPage + 1);
      for (let i = start; i <= end; i++) pages.push(i);
      if (currentPage < totalPages - 2) pages.push("...");
      pages.push(+totalPages);
    }
    return pages;
  };

  return (
    <div className={styles.pagination}>
      <button
        className={styles.arrow}
        onClick={() => onChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        Précédent
      </button>
      <div className={styles.paginationNumbers}>
        {getPageNumbersToShow().map((page, index) => (
          <button
            key={index}
            className={clsx(styles.pageBtn, {
              [styles.active]: page === currentPage,
            })}
            onClick={() => typeof page === "number" && onChange(+page)}
            disabled={page === "..."}
          >
            {page}
          </button>
        ))}
      </div>
      <button
        className={styles.arrow}
        onClick={() => {
          onChange(currentPage + 1);
        }}
        disabled={currentPage === totalPages}
      >
        Suivant
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
};

export default Pagination;
