import clsx from "clsx";
import styles from "./Alert.module.css";

type AlertBoxProps = {
  type: "warning" | "success" | "error" | "info";
  message: string;
};

function AlertBox({ type, message }: AlertBoxProps) {
  return (
    <div className={clsx(styles.alertBox, styles[type])}>
      <p>{message}</p>
    </div>
  );
}

export default AlertBox;
