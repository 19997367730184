"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.saleCancelRequestStatusObj = exports.saleTypesObj = void 0;
exports.saleTypesObj = {
    VENTE: "Vente",
    ANNULATION: "Annulation",
    AVOIR: "Retour magasin",
    ECHANGE: "Échange",
    RETOUR_INTERNET: "Retour internet",
};
exports.saleCancelRequestStatusObj = {
    PENDING: "PENDING",
    ACCEPTED: "ACCEPTED",
    REFUSED: "REFUSED",
};
