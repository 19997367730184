import api from "@api/api";
import { CustomerListParams, customersKeys } from "./queryKeys";
import { AxiosResponse } from "axios";
import { RetailCustomer } from "@pxp-caisse/shared/types/customer";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { useQuery } from "@tanstack/react-query";
import { SeekPaginationResponse } from "@pxp-caisse/shared/types/common";

const getCustomers = async (
  params: CustomerListParams
): Promise<SeekPaginationResponse<RetailCustomer>> => {
  const { data }: AxiosResponse<SeekPaginationResponse<RetailCustomer>> =
    await api.get("/customers", {
      params,
    });
  return data;
};

export const useGetCustomers = (params: CustomerListParams) => {
  return useQuery<ExtractFnReturnType<typeof getCustomers>>({
    queryKey: customersKeys.list(params),
    queryFn: () => getCustomers(params),
  });
};
