import { Turnover } from "@pxp-caisse/shared/types/sale";
import { salesHistoryKeys, SalesListParams } from "./queryKeys";
import api from "@api/api";
import { AxiosResponse } from "axios";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";
import { useQuery } from "@tanstack/react-query";

const getTurnover = async (params: SalesListParams): Promise<Turnover> => {
  const { data }: AxiosResponse<Turnover> = await api.get("/sales/turnover", {
    params,
  });
  return data;
};

type QueryFnType = typeof getTurnover;

export const useGetTurnover = (params: SalesListParams) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: salesHistoryKeys.turnover(params),
    queryFn: () => getTurnover(params),
  });
};
