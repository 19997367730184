import { CSSProperties } from "react";

type TableCellProps = {
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
};

const TableCell = ({ children, style, className }: TableCellProps) => {
  return (
    <td className={className} style={style}>
      {children}
    </td>
  );
};

export default TableCell;
