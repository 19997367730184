import React, { useEffect, useRef, useState } from "react";
import moment, { Moment } from "moment";
import style from "./Date.module.css";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import "moment/locale/fr";
import Button from "@components/Button/Button";

interface HeadingProps {
  date: Moment;
  changeMonth: (month: number) => void;
  changeYear: (year: number) => void;
  resetDate: () => void;
}

const Heading = ({
  date,
  changeMonth,
  changeYear,
  resetDate,
}: HeadingProps) => {
  const yearSelectOptions = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );
  return (
    <div className={style.calendarHeader}>
      <button type="button" onClick={() => changeMonth(date.month() - 1)}>
        <FontAwesomeIcon className={clsx(style.arrow)} icon={faArrowLeft} />
      </button>
      <h1>
        <span onClick={resetDate}>{date.locale("fr").format("MMMM")}</span>{" "}
        <select
          className={style.yearSelect}
          value={date.format("YYYY")}
          onChange={(e) => changeYear(parseInt(e.target.value))}
        >
          {yearSelectOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </h1>
      <button type="button" onClick={() => changeMonth(date.month() + 1)}>
        <FontAwesomeIcon className={clsx(style.arrow)} icon={faArrowRight} />
      </button>
    </div>
  );
};

interface DayProps {
  currentDate: Moment;
  date: Moment;
  startDate: Moment | null;
  endDate: Moment | null;
  onClick: (date: Moment) => void;
}

const Day = ({ currentDate, date, startDate, endDate, onClick }: DayProps) => {
  const isMuted = !date.isSame(currentDate, "month");
  const isStart = startDate && date.isSame(startDate, "day");
  const isEnd = endDate && date.isSame(endDate, "day");
  const isSelected = isStart || isEnd;
  const isBetween = startDate && endDate && date.isBetween(startDate, endDate);
  const isRangeSelected =
    startDate && endDate && !startDate.isSame(endDate, "day");
  const isStartOfWeek = date.day() === 1;
  const isEndOfWeek = date.day() === 0;

  return (
    <div className={style.day}>
      <span
        onClick={() => onClick(date)}
        className={clsx(style.innerDay, {
          [style.muted]: isMuted,
          [style.selected]: isSelected,
          [style.between]: isBetween,
          [style.leftRounded]: isBetween && isStartOfWeek,
          [style.rightRounded]: isBetween && isEndOfWeek,
        })}
      >
        {date.date()}
      </span>
      <div
        className={clsx({
          [style.left]: isRangeSelected && isEnd && !isStartOfWeek,
          [style.right]: isRangeSelected && isStart && !isEndOfWeek,
        })}
      />
    </div>
  );
};

interface DaysProps {
  date: Moment;
  startDate: Moment | null;
  endDate: Moment | null;
  onClick: (date: Moment) => void;
}

const Days = ({ date, startDate, endDate, onClick }: DaysProps) => {
  const thisDate = moment(date);
  const daysInMonth = date.daysInMonth();
  const firstDayDate = date.clone().startOf("month");
  const lastDayDate = date.clone().endOf("month");
  const previousMonth = date.clone().subtract(1, "month");
  const previousMonthDays = previousMonth.daysInMonth();
  const nextMonth = date.clone().add(1, "month");
  const days: JSX.Element[] = [];
  const labels: JSX.Element[] = [];

  for (let i = 1; i <= 7; i++) {
    labels.push(
      <span key={i} className={clsx(style.label)}>
        {moment().locale("FR").day(i).format("dd")}
      </span>
    );
  }

  const daysBefore = firstDayDate.day() === 0 ? 5 : firstDayDate.day() - 2;
  const daysAfter = lastDayDate.day() === 0 ? 0 : 7 - lastDayDate.day();

  for (let i = daysBefore; i >= 0; i--) {
    previousMonth.date(previousMonthDays - i);
    days.push(
      <Day
        key={previousMonth.format("DD MM YYYY")}
        onClick={onClick}
        currentDate={date}
        date={previousMonth.clone()}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);
    days.push(
      <Day
        key={thisDate.format("DD MM YYYY")}
        onClick={onClick}
        currentDate={date}
        date={thisDate.clone()}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  for (let i = 1; i <= daysAfter; i++) {
    nextMonth.date(i);
    days.push(
      <Day
        key={nextMonth.format("DD MM YYYY")}
        onClick={onClick}
        currentDate={date}
        date={nextMonth.clone()}
        startDate={startDate}
        endDate={endDate}
      />
    );
  }

  if (lastDayDate.day() !== 0) {
  }

  return (
    <div>
      <div className={style.labels}>{labels}</div>
      <div className={style.days}>{days}</div>
    </div>
  );
};

interface PickerTriggerProps {
  onClick: () => void;
  selectedDates: Moment[];
}

const PickerTrigger = ({ onClick, selectedDates }: PickerTriggerProps) => {
  const startDate = selectedDates[0];
  const endDate = selectedDates.length > 1 ? selectedDates[1] : null;
  return (
    <div className={style.trigger} onClick={onClick}>
      <FontAwesomeIcon
        className={clsx(style.arrow)}
        icon={faCalendar}
        strokeWidth={1}
      />
      <div>
        <span key={"startDate"}>
          {startDate?.format("DD MMM YYYY") ?? "dd/mm/yyyy"}
        </span>
        {(endDate || selectedDates.length > 1) && (
          <>
            {" - "}
            <span key={"endDate"}>
              {endDate?.format("DD MMM YYYY") ?? "dd/mm/yyyy"}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

type DatePickerProps = {
  onChange?: () => void;
  defaultValue?: Date;
  position?: "top" | "bottom";
  selectedByDefault?: boolean;
} & (
  | {
      type: "date";
      setSelectedDate: (startDate: Date) => void;
    }
  | {
      type: "dateRange";
      setSelectedStartDate: (startDate: Date) => void;
      setSelectedEndDate: (endDate: Date) => void;
      defaultEndValue?: Date;
    }
);

const DatePicker = ({
  onChange,
  defaultValue,
  position = "bottom",
  selectedByDefault = true,
  ...props
}: DatePickerProps) => {
  const [date, setDate] = useState<Moment>(
    defaultValue ? moment(defaultValue) : moment()
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [startDate, setStartDate] = useState<Moment | null>(
    selectedByDefault
      ? defaultValue
        ? moment(defaultValue)
        : props.type === "date"
        ? moment()
        : moment().subtract(7, "days")
      : null
  );
  const [endDate, setEndDate] = useState<Moment | null>(
    props && props.type === "dateRange" && selectedByDefault
      ? moment(props?.defaultEndValue) ?? moment()
      : null
  );

  const dropdownRef = useRef<HTMLDivElement>(null);
  const latestStartDate = useRef(startDate);
  const latestEndDate = useRef(endDate);

  useEffect(() => {
    latestStartDate.current = startDate;
    latestEndDate.current = endDate;
  }, [startDate, endDate]);

  useEffect(() => {
    if (props.type === "date") {
      props.setSelectedDate(startDate?.toDate());
    }

    if (props.type === "dateRange") {
      props.setSelectedStartDate(startDate?.toDate());
      props.setSelectedEndDate(endDate?.toDate());
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        onValidate();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const onTriggerClick = () => {
    if (isOpen) {
      onValidate();
      return;
    }

    setIsOpen(true);
    setIsClosing(false);
  };

  const resetDate = () => setDate(moment());

  const changeMonth = (month: number) => {
    setDate((prevDate) => prevDate.clone().month(month));
  };

  const onValidate = () => {
    if (props.type === "date") {
      props.setSelectedDate(latestStartDate.current?.toDate());
    }

    if (props.type === "dateRange") {
      props.setSelectedStartDate(latestStartDate.current?.toDate());
      props.setSelectedEndDate(latestEndDate.current?.toDate());
    }

    setIsClosing(true);
    if (onChange) onChange();
    setTimeout(() => setIsOpen(false), 300);
  };

  const changeDate = (selectedDate: Moment | null) => {
    if (props.type !== "date") return;

    if (!selectedDate) {
      setStartDate(null);
      props.setSelectedDate(null);
      return;
    }

    setStartDate(selectedDate.clone());
    props.setSelectedDate(selectedDate.toDate());
  };

  const changeYear = (year: number) => {
    setDate((prevDate) => prevDate.clone().year(year));
  };

  const changeRangeDate = (selectedDate: Moment) => {
    if (props.type !== "dateRange") return;

    if (
      !startDate ||
      selectedDate.isBefore(startDate, "day") ||
      !startDate.isSame(endDate, "day")
    ) {
      setStartDate(selectedDate.clone());
      setEndDate(selectedDate.clone());
      return;
    }

    if (
      selectedDate.isSame(startDate, "day") &&
      selectedDate.isSame(endDate, "day")
    ) {
      setStartDate(null);
      setEndDate(null);
      return;
    }

    if (selectedDate.isAfter(startDate, "day")) {
      setEndDate(selectedDate.clone());
    }
  };

  const onChangeDate = (selectedDate: Moment | null) => {
    if (props.type === "date") {
      changeDate(selectedDate);
      return;
    }
    changeRangeDate(selectedDate);
  };

  return (
    <div className={style.wrapper}>
      <PickerTrigger
        onClick={onTriggerClick}
        selectedDates={
          props.type === "date" ? [startDate] : [startDate, endDate]
        }
      />
      {isOpen && (
        <div
          ref={dropdownRef}
          className={clsx(style.calendar, style.fade, {
            [style.fade_reverse]: isClosing,
            [style.top]: position === "top",
          })}
        >
          <Heading
            date={date}
            changeMonth={changeMonth}
            resetDate={resetDate}
            changeYear={changeYear}
          />
          <Days
            date={date}
            startDate={startDate}
            endDate={endDate}
            onClick={onChangeDate}
          />
          <Button type="button" onClick={onValidate}>
            Valider
          </Button>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
