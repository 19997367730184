import React from "react";
import styles from "./Separator.module.css";

type SeparatorProps = {
  direction?: "horizontal" | "vertical";
};

function Separator({ direction }: SeparatorProps) {
  return <div className={styles[direction]}></div>;
}

export default Separator;
