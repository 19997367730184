import api from "@api/api";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { RetailCustomer } from "@pxp-caisse/shared/types/customer.type";
import { CustomerShowParams, customersKeys } from "./queryKeys";
import { ExtractFnReturnType } from "@providers/ReactQueryClientProvider";

const getCustomer = async (params: CustomerShowParams) => {
  const {
    data,
  }: AxiosResponse<{
    customer: RetailCustomer;
    fidelityInfo?: any;
    availableVouchers?: any;
  }> = await api.get(`/customers/${params.id}`, {
    params: {
      all: params.all,
    },
  });
  return data;
};

export const useGetCustomer = (params: CustomerShowParams) => {
  return useQuery<ExtractFnReturnType<typeof getCustomer>>({
    queryKey: customersKeys.show(params),
    queryFn: () => getCustomer(params),
  });
};
