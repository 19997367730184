import { CSSProperties } from "react";
import styles from "./Table.module.css";
import TableCell from "./TableCell";
import clsx from "clsx";

type TableRowProps = {
  style?: CSSProperties;
  children: React.ReactElement<typeof TableCell>[];
  onClick?: () => void;
  disableHover?: boolean;
};

const TableRow = ({
  style,
  children,
  onClick,
  disableHover = false,
}: TableRowProps) => {
  return (
    <tr
      className={clsx(styles.row, {
        [styles.clickable]: onClick,
        [styles.disableHover]: disableHover,
      })}
      style={style}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};

export default TableRow;
