import clsx from "clsx";
import styles from "./Radio.module.css";

type RadioButtonProps = {
  selected: boolean;
  setSelected: (value: any) => void;
  value: string;
  label: string;
};

function RadioButton({
  selected,
  setSelected,
  label,
  value,
}: RadioButtonProps) {
  return (
    <label
      className={clsx(styles.radioContainer, { [styles.selected]: selected })}
    >
      <input
        type="radio"
        name="option"
        value={value}
        checked={selected}
        onChange={() => {
          setSelected(value);
        }}
        className={styles.input}
      />
      <span className={styles.label}>{label}</span>
    </label>
  );
}

export default RadioButton;
